import React, { useState, useEffect, useRef } from "react";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import SubjectsHeader from "../../../components/common/subjectsHeader";
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getWorksheetsAction} from '../../../commonData/worksheets/action';
import WorksheetItem from '../../../components/common/worksheetItem';
import {getSubjectsAction} from '../../../commonData/subjects/action';
import {getSpecificItemById, isObjEmpty} from '../../../utils/common';
import {getGradesAction} from '../../../commonData/grades/action';
import {getChaptersAction} from '../../../commonData/chapters/action';
import {getGradeChaptersAction} from '../../../commonData/gradeChapters/action';

const GradeStrand = ({ text, isVisible, isSelected, onClick }) => (
  <li
    className={` text-14px md:text-14.5px lg:text-15px font-normal leading-normal tracking-wide hover:text-secondary-red-500 dark:hover:text-secondary-red-500  px-4 pl-10 py-1 ${isSelected ? "text-primary-main-700 dark:text-primary-main-700 font-medium" : "text-app-colors-p-small dark:text-app-colors-p-small-d"} ${isVisible ? "block" : "hidden"} cursor-pointer`}
    onClick={onClick}
  >
    {text}
  </li>
);

const GradeSubject = ({ text, topics }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedSubSubItem, setSelectedSubSubItem] = useState(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubSubItemClick = (index) => {
    setSelectedSubSubItem(index);
  };

  return (
    <li className={`${isExpanded ? "surface-hover" : "surface-hover"}`}>
      <div
        className="px-4 pl-8 py-2 flex justify-between items-center cursor-pointer"
        onClick={handleToggle}
      >
        <h6 className="text-xs md:text-sm lg:text-base font-medium line-clamp-1">
          {text}
        </h6>
        {isExpanded ? (
          <svg
            className="icons"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.64019 7.86012H5.30686V9.19345H6.64019V7.86012ZM1.26019 2.87012L0.317526 3.81278L4.08819 7.58412L5.03086 6.64145L1.26019 2.87012ZM10.6875 2.87012L6.91686 6.64145L7.85953 7.58412L11.6309 3.81278L10.6875 2.87012Z"
            />
          </svg>
        ) : (
          <svg
            className="icons"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.86012 5.35981V6.69314H9.19345V5.35981H7.86012ZM2.87012 10.7398L3.81278 11.6825L7.58412 7.91181L6.64145 6.96914L2.87012 10.7398ZM2.87012 1.31247L6.64145 5.08314L7.58412 4.14047L3.81278 0.369141L2.87012 1.31247Z"
            />
          </svg>
        )}
      </div>
      <ul className={`${isExpanded ? "block" : "hidden"}`}>
        {topics.map((topic, index) => (
          <GradeStrand
            key={index}
            text={topic.title}
            isVisible={isExpanded}
            isSelected={selectedSubSubItem === index}
            onClick={() => handleSubSubItemClick(index)}
          />
        ))}
      </ul>
    </li>
  );
};

const GradeItem = ({ text, chapters }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li
      className={`border-b border-app-colors-border dark:border-app-colors-border-d  ${isExpanded ? null : null}`}
    >
      <div
        className="flex justify-between items-center px-4 py-2 cursor-pointer surface-hover"
        onClick={handleToggle}
      >
        <h6 className=" text-xs md:text-sm lg:text-base line-clamp-1">
          {text}
        </h6>
        {isExpanded ? (
          <svg
            className="icons"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.64019 7.86012H5.30686V9.19345H6.64019V7.86012ZM1.26019 2.87012L0.317526 3.81278L4.08819 7.58412L5.03086 6.64145L1.26019 2.87012ZM10.6875 2.87012L6.91686 6.64145L7.85953 7.58412L11.6309 3.81278L10.6875 2.87012Z"
            />
          </svg>
        ) : (
          <svg
            className="icons"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.86012 5.35981V6.69314H9.19345V5.35981H7.86012ZM2.87012 10.7398L3.81278 11.6825L7.58412 7.91181L6.64145 6.96914L2.87012 10.7398ZM2.87012 1.31247L6.64145 5.08314L7.58412 4.14047L3.81278 0.369141L2.87012 1.31247Z"
            />
          </svg>
        )}
      </div>
      <ul style={{ display: isExpanded ? "block" : "none" }}>
        {chapters.map((chapter, index) => (
          <GradeSubject
            key={index}
            text={chapter.name}
            topics={chapter.topics}
          />
        ))}
      </ul>
    </li>
  );
};

const ExpandableList = ({ data }) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedSubItem, setSelectedSubItem] = useState(null);

  const isItemExpanded = (index) => expandedItems.includes(index);

  const handleItemClick = (index, subIndex) => {
    setExpandedItems([index]);
    setSelectedSubItem(subIndex);
  };

  return (
    <ul>
      {data.map((item, index) => (
        <li
          key={index}
          className={`border-b border-gray-200 p-2 ${isItemExpanded(index) ? null : "hover:bg-i-purple-300 hover:text-white"}`}
        >
          <div
            onClick={() => handleItemClick(index, null)}
            className={`cursor-pointer flex justify-between`}
          >
            <span>{item.name}</span>
            <span>
              {isItemExpanded(index) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              )}
            </span>
          </div>
          {expandedItems.includes(index) && item.subItems && (
            <ul>
              {item.subItems.map((subItem, subIndex) => (
                <li
                  key={subIndex}
                  onClick={() => setSelectedSubItem(subIndex)}
                  className={`cursor-pointer p-2 ${selectedSubItem === subIndex ? "bg-i-purple-500 text-white" : "bg-transparent text-black"} hover:bg-i-purple-300 hover:text-white`}
                >
                  {subItem}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

const LibrarySubjects = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subjects = useSelector(state => state.getSubjects.payload.data);
  const grades = useSelector(state => state.getGrades.payload.data);
  const chapters = useSelector((state) => state.getChapters.payload.data);
  const gradeChapters = useSelector((state) => state.getGradeChapters.payload);
  const worksheets = useSelector(state => state.getWorksheets.payload.data);
  const libraries = useSelector(state => state.getLibraries.payload.data);

  const [specificLibrary, setSpecificLibrary] = useState({});
  const [isOpen, setIsOpen] = useState(false);  // To toggle the dropdown

  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const getSubjectById = (id, subjectsArray) => {
    const index = subjectsArray.findIndex(subject => subject.id === id);
    return index !== -1 ? { subject: subjectsArray[index], index } : null;
  };

  const selectedSubject = getSubjectById(params.subjectId, subjects) || {};
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (selectedSubject && !selectedItem.label) {
      setSelectedItem({label: selectedSubject.subject, index: selectedSubject.index});
    }
  }, [selectedSubject, selectedItem]);

  useEffect(() => {
    const availableGradeIds = grades?.map(grade => grade.id);
    if (params.subjectId && grades && grades.length > 0) {
      (async () => dispatch(getChaptersAction(params.subjectId, availableGradeIds)))();
    }
  }, [dispatch, grades, params.subjectId]);

  const handleSelectItem = (item, index) => {
    setSelectedItem({ label: item, index });  // Update selected item and index
    setIsOpen(false);
    if (item.id) {
      const path = `/user_space/${params.libraryId}/my_workspace/subjects/${item.id}`;
      navigate(path, { state: {chosenSubject: "Yeah"} });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const curriculumLevelId = specificLibrary?.curriculumLevel?.id;

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      setSpecificLibrary(getSpecificItemById(libraries, params.libraryId));
    }
  }, [libraries]);

  useEffect(() => {
    if (curriculumLevelId) {
      (async () => dispatch(getSubjectsAction(curriculumLevelId)))();
      (async () => dispatch(getGradesAction(curriculumLevelId)))();
    }
  }, [dispatch, curriculumLevelId]);

  useEffect(() => {
    if (!isObjEmpty(selectedItem) && grades && grades.length > 0) {
      (async () => dispatch(getGradeChaptersAction(selectedItem?.label?.id, grades)))();
    }
  }, [grades, selectedItem, dispatch]);

  const gradeItems = [
    {
      grade: "Grade 7",
      chapters: [
        {
          name: "Newtonian Mechanics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Electricity and Magnetism",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Waves and Optics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Thermodynamics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
      ],
    },
    {
      grade: "Grade 8",
      chapters: [
        {
          name: "Newtonian Mechanics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Electricity and Magnetism",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Waves and Optics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Thermodynamics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
      ],
    },
    {
      grade: "Grade 9",
      chapters: [
        {
          name: "Newtonian Mechanics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Electricity and Magnetism",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Waves and Optics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
        {
          name: "Thermodynamics",
          topics: [
            "General",
            "Geometrical optics",
            "Wave Optics",
            "Optical Instruments and Applications",
          ],
        },
      ],
    },
  ];

  const items2 = {
    "PP1": [
      {
        "name": "Classification",
        "topics": [
          "Sorting and grouping",
          "Matching and pairing",
          "Ordering",
          "Patterns"
        ]
      }
    ],
    "PP2": [
      {
        "name": "Measurement",
        "topics": [
          "Sides of objects",
          "Mass",
          "Capacity",
          "Time",
          "Money",
          "Area"
        ]
      }
    ]
  }

  const sampleData = [
    { name: "Item 1", subItems: ["Subitem 1.1", "Subitem 1.2"] },
    { name: "Item 2", subItems: ["Subitem 2.1", "Subitem 2.2"] },
    { name: "Item 3", subItems: ["Subitem 3.1", "Subitem 3.2"] },
  ];

  const sidebarRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      window.innerWidth < 1024
    ) {
      setMenuOpen(false);
    }
  };

  const handleWorksheetPage = (worksheetId) => {
    const path = `/user_space/${params.libraryId}/my_workspace/subjects/${params.subjectId}/worksheet/${worksheetId}`
    navigate(path);
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Update the state or perform any necessary actions based on the new window.innerWidth value
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenuContent = () => {
    setMenuOpen(!menuOpen);
    document.getElementById("content-menu").classList.toggle("hidden");
  };

  useEffect(() => {
    if (params.libraryId && params.subjectId) {
      (async () => dispatch(getWorksheetsAction(params.libraryId, params.subjectId)))();
    }
  }, [dispatch, params.libraryId, params.subjectId]);

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar />
      <div className="mt-0 mx-0 lg:ml-20 min-h-full">
        <SubjectsHeader
          subjectName={`${selectedItem?.label?.name} (${selectedItem?.label?.code})`}
          subjectDescription={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique nisl vitae volutpat. Nulla aliquet porttitor venenatis. Donec a dui et dui fringilla consectetur id nec massa. Aliquam erat volutpat. Sed ut dui ut lacus dictum fermentum vel tincidunt neque. Sed sed lacinia lectus. Duis sit amet sodales felis. Duis nunc eros, mattis at dui ac, convallis semper risus. In adipiscing ultrices tellus, in suscipit massa vehicula eu"
          }
        />
        <div
          className="z-20 fixed top-20 lg:top-24 left-4 md:left-8 flex items-center lg:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <button
            id="content-menu-toogle"
            className="group flex gap-4 items-center justify-center rounded-md bg-app-colors-body-bg dark:bg-app-colors-body-bg-d surface-hover h-12 px-4 "
          >
            <svg
              className="icons text-hover-active"
              width="20"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66667 0H0V2.66667H2.66667V0ZM5.33333 0V2.66667H24V0H5.33333ZM8 8H0V10.6667H8V8ZM10.6667 8V10.6667H13.3333V8H10.6667ZM16 10.6667H24V8H16V10.6667ZM0 18.6667H18.6667V16.0013H0V18.6667ZM21.3333 18.6667H24V16.0013H21.3333V18.6667Z"
              />
            </svg>
            <p className="small font-semibold text-hover-active">Expand Menu</p>
          </button>
        </div>

        <div className="flex justify-center">
          <div className="flex flex-col w-full max-w-8xl mx-4 md:mx-8 xl:mx-14 2xl:mx-20 pt-2 md:pt-4 lg:pt-6 pb-2 md:pb-4 lg:pb-6 xl:gap-6 text-sm">
            <div className=" body z-10 sticky top-16 lg:top-20 mt-4 border-b border-gray-200 dark:border-gray-700 flex flex-col gap-4 pb-4 lg:flex-row items-center justify-between w-full">
              <div className="w-full">
                <button
                  onClick={handleDropdownToggle}
                  className="select-none surface flex justify-between items-center rounded-t-md w-full lg:w-96 sticky pt-12 pb-6 lg:pt-6 lg:pb-3 px-4 border-b-2 border-app-colors-border dark:border-app-colors-border-d"
                >
                  <div className="text-left w-full">
                    <p className="small pb-2 line-clamp-1">
                      Pacoima Charter Elementary School
                    </p>
                    <h6 className="line-clamp-1">{`${selectedItem?.label?.name} (${selectedItem?.label?.code})`}</h6>
                  </div>
                  <svg
                    className="icons"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64019 7.86012H5.30686V9.19345H6.64019V7.86012ZM1.26019 2.87012L0.317526 3.81278L4.08819 7.58412L5.03086 6.64145L1.26019 2.87012ZM10.6875 2.87012L6.91686 6.64145L7.85953 7.58412L11.6309 3.81278L10.6875 2.87012Z"
                    />
                  </svg>
                </button>
                {isOpen && (
                  <div className="select-none absolute z-10 bg-app-colors-body-bg dark:bg-app-colors-body-bg-d rounded-md w-full lg:w-96 py-4">
                    <ul
                        className="w-full borders rounded-md overflow-y-auto t-40"
                        style={{maxHeight: 'calc(5 * 3.3rem)'}}
                    >
                      {subjects.length > 0 ? (
                        subjects.map((subject, index) => (
                          <li
                            key={index + 1}
                            className="group p-2 border-b border-app-colors-border dark:border-app-colors-border-d cursor-pointer surface-hover"
                            onClick={() => handleSelectItem(subject, index)}
                          >
                            {`${subject?.name} ${subject?.code}`}
                          </li>
                        ))
                      ):(
                        <li>No subjects found</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="w-full lg:max-w-max h-14 flex justify-between sm:gap-2 md:gap-4">
                <div className="hidden sm:flex gap-2">
                <button className="primary-button flex justify-center items-center w-14 h-14 rounded-md text-white bg-primary-main-700">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.36194 16.0001L0.0281563 13.0001L2.47305 13.0001V10.0001H4.25083V13.0372L6.52818 13.0001L3.36194 16.0001Z"
                        fill="white"
                      />
                      <path
                        d="M2.47305 8.76331H4.25083V7.26331H2.47305V8.76331Z"
                        fill="white"
                      />
                      <path
                        d="M12.0293 9.40085H13.8066V3.00012H16.084L12.9178 0.00012207L9.58398 3.00012H12.0289L12.0293 9.40085Z"
                        fill="white"
                      />
                      <path
                        d="M6.02637 6.50012H10.5237L10.5257 5.00012H6.02637V6.50012Z"
                        fill="white"
                      />
                      <path
                        d="M6.02621 8.87844H10.5262L10.5243 7.37844H6.02621V8.87844Z"
                        fill="white"
                      />
                      <path
                        d="M6.02899 11.4009H10.5283L10.5264 9.90085H6.02899V11.4009Z"
                        fill="white"
                      />
                    </svg>
                  </button>

                  <button className="flex justify-center items-center blue-button rounded-button text-white">
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.7507 11.5556C10.7507 12.7156 11.4947 13.6916 12.5284 14.0587V16H14.3062V14.0587C15.3391 13.6916 16.084 12.7156 16.084 11.5556C16.084 10.3956 15.3391 9.41956 14.3062 9.05244V3.55556H12.5284V9.05244C11.4947 9.41956 10.7507 10.3956 10.7507 11.5556ZM14.3062 10.6667V12.4444H12.5284V10.6667H14.3062ZM5.41732 4.44444C5.41732 5.60444 6.16221 6.58044 7.1951 6.94756L7.1951 16H8.97287V6.94756C10.0067 6.58044 10.7507 5.60356 10.7507 4.44444C10.7507 3.28533 10.0058 2.30844 8.97287 1.94133L8.97287 0H7.1951V1.94133C6.16132 2.30844 5.41732 3.28444 5.41732 4.44444ZM8.97287 3.55556V5.33333H7.1951V3.55556H8.97287ZM1.86176 9.05244C0.827984 9.41956 0.0839844 10.3956 0.0839844 11.5556C0.0839844 12.7156 0.827984 13.6916 1.86176 14.0587L1.86176 16H3.63954V14.0587C4.67243 13.6916 5.41732 12.7156 5.41732 11.5556C5.41732 10.3956 4.67332 9.41956 3.63954 9.05244L3.63954 0H1.86176L1.86176 9.05244ZM3.63954 10.6667L3.63954 12.4444H1.86176V10.6667H3.63954ZM14.3062 1.77778L14.3062 0H12.5284L12.5284 1.77778H14.3062Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>

                <form className="flex w-full items-center surface pl-4 border rounded border-app-colors-border dark:border-app-colors-border-d focus:ring-app-colors-border dark:focus:ring-app-colors-border-d">
                  <div className="w-full flex">
                    <div className="w-full flex">
                      <div className="flex items-center pointer-events-none">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.4886 1.78514C8.10837 -0.595048 4.24932 -0.595048 1.86913 1.78514C-0.511063 4.16533 -0.511063 8.02514 1.86913 10.4046C4.24932 12.7848 8.10913 12.7848 10.4886 10.4046C12.8687 8.02514 12.8687 4.16533 10.4886 1.78514ZM9.41122 9.32724C7.62608 11.1124 4.73084 11.1124 2.94646 9.32724C1.16132 7.54209 1.16132 4.64762 2.94646 2.86248C4.7316 1.07733 7.62684 1.07733 9.41122 2.86248C11.1964 4.64686 11.1964 7.54209 9.41122 9.32724Z"
                              fill="#A3A7C4"
                            />
                            <path
                              d="M12.0957 10.9541L13.1729 9.87616L14.2514 10.9539L13.1742 12.0318L12.0957 10.9541Z"
                              fill="#A3A7C4"
                            />
                            <path
                              d="M14.2538 14.1825C13.9559 14.4812 13.4744 14.4812 13.1765 14.1825L9.94449 10.9505L9.52773 10.5246L9.83021 10.2222L11.6778 8.37454L11.0218 7.71777L9.94449 8.79511L8.8664 9.8732L7.78906 10.9505L8.8664 12.0279L12.0992 15.2599C12.9906 16.1513 14.4405 16.1513 15.3312 15.2599C16.2226 14.3692 16.2226 12.9193 15.3312 12.0279L14.2538 13.1052C14.5517 13.4031 14.551 13.8862 14.2538 14.1825Z"
                              fill="#A3A7C4"
                            />
                          </g>
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full surface py-4 text-sm border-0 focus:ring-0 text-gray-900 dark:text-white dark:placeholder-gray-400"
                        placeholder="Search Content ..."
                        required
                      />
                    </div>

                    <button
                      type="submit"
                      className="primary-button px-4 text-white focus:ring-0 focus:outline-none font-medium rounded text-sm"
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex w-full">
              <div>
                <div className="select-none lg:sticky lg:top-56 lg:pr-6">
                  <div
                    ref={sidebarRef}
                    className={`content-menu rounded-md ${
                      menuOpen || window.innerWidth >= 1024 ? "block" : "hidden"
                    } lg:flex lg:flex-col surface  ${
                      menuOpen && window.innerWidth < 1024
                        ? " bg-app-colors-body-bg dark:bg-app-colors-body-bg-d md:w-80 lg:w-96 fixed top-20 lg:top-24 left-4 md:left-8 lg:left-28 z-20 flex flex-col w-72"
                        : ""
                    }`}
                  >
                    <div className="relative h-full">
                      <div className="bg-app-colors-body-bg dark:bg-app-colors-body-bg-d lg:bg-app-colors-surface lg:dark:bg-app-colors-surface-d rounded-t-md w-72 md:w-80 lg:w-96 sticky pt-12 pb-6 lg:pt-14 lg:pb-8 px-4 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
                        <h6 className="text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                          Browse Library
                        </h6>
                      </div>
                      <div
                        className="relative pb-4 h-fill overflow-y-auto"
                        style={{ maxHeight: "60vh" }}
                      >
                        <div className="border-b border-app-colors-border dark:border-app-colors-border-d">
                          <h6 className="px-4 py-2 cursor-pointer surface-hover text-xs md:text-sm lg:text-base line-clamp-1">
                            All
                          </h6>
                        </div>
                        <div className="">
                          <ul>
                            {gradeChapters.map((gradeItem, index) => (
                              <GradeItem
                                key={index}
                                text={gradeItem.grade}
                                chapters={gradeItem.chapters}
                              />
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* <div className="bg-white mt-10 rounded pb-2 hidden lg:block">
                      <div className="flex justify-between pt-4 pb-2 px-2 border-b border-gray-200">
                        <div>By Topic</div>
                        <div>-- -</div>
                      </div>
                      <div className="m-2 rounded bg-i-purple-100">
                        <div className="border-b border-gray-200">
                          <div className="p-2">All</div>
                        </div>
                        <div className="">
                          <ExpandableList data={sampleData} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full grid auto-rows-max grid-cols-auto-fill-200 gap-4 ease-in-out transition duration-700">
                {(worksheets && worksheets.length > 0) ? (
                  worksheets.map((worksheet) => (
                    <WorksheetItem
                      key={worksheet.id}
                      worksheetUrl={worksheet.worksheetUrl}
                      title={worksheet.title}
                      description={worksheet.description}
                      courseMaterial={worksheet.courseMaterial.name}
                      authorUserName={`@${getSpecificItemById(libraries, worksheet?.libraryId)?.user?.username}`}
                      handleClick={() => handleWorksheetPage(worksheet.id)}
                    />
                  ))
                ): (
                  <div className="small">There are no worksheets</div>
                )
              }
              </div>

              <div>
                <div className="hidden 2xl:flex 2xl:flex-col 2xl:flex-grow-0 2xl:w-100 2xl:sticky 2xl:top-56 2xl:pl-6">
                  <div className="surface rounded-t pt-12 pb-6 lg:pt-14 lg:pb-8 px-4 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
                    <h6 className="text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                      Suggestions
                    </h6>
                  </div>
                  <div className="flex flex-col gap-4 mt-3">
                    <div className="surface surface-hover borders flex items-center gap-3 p-1.5 rounded-md cursor-pointer">
                      <div className="min-w-16">
                        <img
                          src="/images/physics.jpeg"
                          alt=""
                          className="w-14 bg-cover h-full"
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <h6 className=" text-hover line-clamp-2 text-sm font-medium">
                          Polarization and Its Practical Applications - Grade 7
                        </h6>
                        <div className="flex gap-1">
                          <img
                            src="/images/test-avatar.png"
                            width="20px"
                            alt="avatar"
                            className="mr-2 rounded-full"
                          />

                          <p className="smaller text-hover">@ticet</p>
                        </div>
                        <p className="smaller uppercase">
                          Worksheet - Lecture Notes
                        </p>
                      </div>
                    </div>
                    <div className="surface surface-hover borders flex items-center gap-3 p-1.5 rounded-md cursor-pointer">
                      <div className="min-w-16">
                        <img
                          src="/images/physics.jpeg"
                          alt=""
                          className="w-14 bg-cover h-full"
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <h6 className=" text-hover line-clamp-2 text-sm font-medium">
                          Microscopes: Unveiling the Microscopic World - Grade 7
                        </h6>
                        <div className="flex gap-1">
                          <img
                            src="/images/test-avatar.png"
                            width="20px"
                            alt="avatar"
                            className="mr-2 rounded-full"
                          />

                          <p className="smaller text-hover">@efeuzoma</p>
                        </div>
                        <p className="smaller uppercase">
                          Worksheet - Questions
                        </p>
                      </div>
                    </div>
                    <div className="surface surface-hover borders flex items-center gap-3 p-1.5 rounded-md cursor-pointer">
                      <div className="min-w-16">
                        <img
                          src="/images/physics.jpeg"
                          alt=""
                          className="w-14 bg-cover h-full"
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <h6 className=" text-hover line-clamp-2 text-sm font-medium">
                          Revealing the Cosmos: A Study of Telescopes - Grade 7
                        </h6>
                        <div className="flex gap-1">
                          <img
                            src="/images/test-avatar.png"
                            width="20px"
                            alt="avatar"
                            className="mr-2 rounded-full"
                          />

                          <p className="smaller text-hover">@peterbruce</p>
                        </div>
                        <p className="smaller uppercase">
                          Worksheet - Lecture Notes
                        </p>
                      </div>
                    </div>
                    <div className="surface surface-hover borders flex items-center gap-3 p-1.5 rounded-md cursor-pointer">
                      <div className="min-w-16">
                        <img
                          src="/images/physics.jpeg"
                          alt=""
                          className="w-14 bg-cover h-full"
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <h6 className=" text-hover line-clamp-2 text-sm font-medium">
                          Optical Communication with Fiber Optics - Grade 7
                        </h6>
                        <div className="flex gap-1">
                          <img
                            src="/images/test-avatar.png"
                            width="20px"
                            alt="avatar"
                            className="mr-2 rounded-full"
                          />

                          <p className="smaller text-hover">@elizabethjane</p>
                        </div>
                        <p className="smaller uppercase">
                          Worksheet - Questions
                        </p>
                      </div>
                    </div>
                    <div className="surface surface-hover borders flex items-center gap-3 p-1.5 rounded-md cursor-pointer">
                      <div className="min-w-16">
                        <img
                          src="/images/physics.jpeg"
                          alt=""
                          className="w-14 bg-cover h-full"
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <h6 className=" text-hover line-clamp-2 text-sm font-medium">
                          Gravity Waves and Gravitational Foxtrots: A Celestial
                          Dance
                        </h6>
                        <div className="flex gap-1">
                          <img
                            src="/images/test-avatar.png"
                            width="20px"
                            alt="avatar"
                            className="mr-2 rounded-full"
                          />

                          <p className="smaller text-hover">@elizabethjane</p>
                        </div>
                        <p className="smaller uppercase">
                          Worksheet - Questions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibrarySubjects;
