import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  LIBRARY_RATING_LOADING,
  LIBRARY_RATING_FAILURE,
  LIBRARY_RATING_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const libraryRatingLoading = () => ({
  type: LIBRARY_RATING_LOADING
});

export const libraryRatingSuccess = message => ({
  type: LIBRARY_RATING_SUCCESS,
  message
});

export const libraryRatingFailure = error => ({
  type: LIBRARY_RATING_FAILURE,
  error
});

export const libraryRatingAction = ({libraryId, rating, comment}) => async dispatch => {
  const ratingDetails = { libraryId, rating, comment }

  dispatch(libraryRatingLoading())
  console.log('Library rating details -->', ratingDetails)
  axios.post(`${CLIENT_API_URL}/libraries/rate`, ratingDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        console.log('New rating -->', res);
        dispatch(libraryRatingSuccess(['Library rated successfully', res.data]));
      })
      .catch(error => {
        dispatch(libraryRatingFailure({
          message: 'An error occurred while rating library'
        }))
      })
}