import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authVerify } from './authVerify';

const PrivateRoutes = () => {
  const [token, setToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const verifyAuth = async () => {
      const retrievedToken = await authVerify();
      setToken(retrievedToken);
    };

    verifyAuth();
  }, []);

  // If the token is "missing token," redirect to login
  if (token === 'missing token') {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  // Show the private content if token is valid
  return token ? <Outlet /> : null;
};

export default PrivateRoutes;
