import {
  SET_SELECTED_SETTING_LOADING,
  SET_SELECTED_SETTING_FAILURE,
  SET_SELECTED_SETTING_SUCCESS,
} from './constants';
import {isLibrary} from '../../../utils/common';

export const setSelectedSettingLoading = () => ({
  type: SET_SELECTED_SETTING_LOADING
});

export const setSelectedSettingSuccess = payload => ({
  type: SET_SELECTED_SETTING_SUCCESS,
  payload
});

export const setSelectedSettingFailure = error => ({
  type: SET_SELECTED_SETTING_FAILURE,
  error
});

export const setSelectedSettingAction = item => async dispatch => {
  dispatch(setSelectedSettingLoading());
  if (typeof item === 'string' || isLibrary(item)) {
    dispatch(setSelectedSettingSuccess(item));
  } else {
    dispatch(setSelectedSettingFailure({
      message: 'An error occurred while setting item'
    }))
  }
}
