import {
  FOLLOW_LIBRARY_FAILURE,
  FOLLOW_LIBRARY_SUCCESS,
  FOLLOW_LIBRARY_LOADING
} from './constants';
import axios from 'axios';
import {CLIENT_API_URL} from '../../../utils/constants';
import {getJwtTokenCookie} from '../../../utils/cookieHelper';

export const followLibraryLoading = () => {
  return {
    type: FOLLOW_LIBRARY_LOADING
  }
}

export const followLibrarySuccess = result => {
  return {
    type: FOLLOW_LIBRARY_SUCCESS,
    result
  }
}

export const followLibraryFailure = error => {
  return {
    type: FOLLOW_LIBRARY_FAILURE,
    error
  }
}

export const followLibraryAction = ({ libraryId }) => async dispatch => {
  const libDetails = { libraryId }
  dispatch(followLibraryLoading())
  axios.post(`${CLIENT_API_URL}/libraries/follow`, libDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  }).then(resp => {
      console.log('Library follow Successful!', resp.data);
      dispatch(followLibrarySuccess(resp.data));
    }).catch(() => {
      dispatch(followLibraryFailure({
        message: 'Unable to follow library'
      }))
  })
}
