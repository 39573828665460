import React, { useEffect, useState } from 'react';
import SignedInNavBar from '../../../components/navbars/signedInNavbar';
import {
  appendItemToArray, cleanWorksheetName,
  findUserRole,
  getFullName,
  getSpecificItemById,
  isObjEmpty,
  removeItemFromArray,
} from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {useParams} from 'react-router-dom';
import PersonalLibraryHeader from '../../../components/common/personalLibraryHeader';
import BorderedTextInput from '../../../components/common/borderedTextInput';
import BorderedTextArea from '../../../components/common/borderedTextArea';
import BorderSelectInput from '../../../components/common/borderSelectInput';
import BorderedDropdownTag from '../../../components/common/BorderedDropdownTag';
import PreviewPdf from '../../../utils/previewPDF';
import {getCurriculumSubjectsAction} from '../personalLibrary/action';
import {getGradesAction} from '../../../commonData/grades/action';
import {getCourseMaterialsAction} from '../../../commonData/courseMaterials/action';
import {getChaptersAction} from '../../../commonData/chapters/action';
import {createWorksheetAction} from './action';
import {getUncategorizedWorksheetsAction} from '../../../commonData/uncategorizedWorksheets/action';
import {uploadWorksheetAction} from '../../../commonData/worksheetUpload/action';
import {libraryRatingAction} from '../../../commonData/libraryRating/action';

export default function UpdateLibraryWorksheets() {
  const [worksheetInfo, setWorksheetInfo] = useState({});
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [gradeIds, setGradeIds] = useState([]);
  const [formFilled, setFormFilled] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();

  const uploadInfo = useSelector((state) => state.uploadImage);
  const curriculumSubjects = useSelector(state => state.getCurriculumSubjects.payload.data)
  const grades = useSelector((state) => state.getGrades.payload.data);
  const courseMaterials = useSelector((state) => state.getCourseMaterials.payload.data);
  const chapters = useSelector((state) => state.getChapters.payload.data) || [];
  const libraries = useSelector((state) => state.getLibraries.payload.data);
  const uncategorizedWorksheets = useSelector((state) => state.getUncategorizedWorksheets.payload.data);

  const [specificLibrary, setSpecificLibrary] = useState({});
  const [rating, setRating] = useState(0);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [pdfFiles, setPdfFiles] = useState([]);

  let chapterOptions = [];
  const topicOptions = [];

  chapters && chapters.length > 0 && chapters?.forEach((chapter) => {
    chapterOptions.push({ value: chapter.id, label: `${chapter.title} - ${chapter.code}` });
  });

  topics && topics.length > 0 && topics?.forEach((topic) => {
    topicOptions.push({ value: topic.id, label: `${topic.title} - ${topic.code}` });
  });

  const handleChange = (e) => {
    setWorksheetInfo({ ...worksheetInfo, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e, field, item) => {
    if (field === "chapterIds") {
        setSelectedChapters(e);
    } else if (field === "topicIds") {
        setSelectedTopics(e);
    }
    setWorksheetInfo({ ...worksheetInfo, [field]: e });
  };

  const getTopicsFromSelectedChapters = () => {
    const filteredChapters = chapters?.filter(chapter =>
      selectedChapters.some(selected => selected.value === chapter.id)
    );
    const newTopics = filteredChapters?.flatMap(chapter => chapter.topics);
    setTopics(newTopics);
  };

  const userDetails = useSelector((state) => state.getUserDetails.payload);
  const curriculumLevelId = specificLibrary?.curriculumLevel?.id;

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      setSpecificLibrary(getSpecificItemById(libraries, params.libraryId));
    }
  }, [libraries]);

  useEffect(() => {
    if (!isObjEmpty(userDetails) && curriculumLevelId) {
      let role = findUserRole(userDetails.roles);
      (async () => dispatch(getCurriculumSubjectsAction(curriculumLevelId, role)))();
    }
  }, [userDetails, dispatch])

  useEffect(() => {
    if (curriculumLevelId) {
      (async() => dispatch(getGradesAction(curriculumLevelId)))();
    }
  }, [dispatch, curriculumLevelId]);

  useEffect(() => {
    (async() => dispatch(getCourseMaterialsAction()))();
  }, [dispatch]);

  useEffect(() => {
    if (worksheetInfo.subjectId) {
      (async () => dispatch(getChaptersAction(worksheetInfo.subjectId, worksheetInfo.gradeIds)))();
    }
  }, [dispatch, selectedGrades]);

  useEffect(() => {
    getTopicsFromSelectedChapters();
  }, [selectedChapters]);

  useEffect(() => {
    if (params.libraryId) {
      (async() => dispatch(getUncategorizedWorksheetsAction(params.libraryId)))();
    }
  }, [dispatch, params.libraryId]);

  useEffect(() => {
    if(!isObjEmpty(specificLibrary)) {
      setRating(specificLibrary?.averageRating || 0)
    }
  }, [specificLibrary]);

  useEffect(() => {
    if (pdfFiles && pdfFiles.length > 0 && !selectedFileId) {
      setSelectedFileId(pdfFiles[0].id);
    }
  }, [pdfFiles, selectedFileId]);

  useEffect(() => {
    setPdfFiles(uncategorizedWorksheets);
  }, [uncategorizedWorksheets]);

  const handleRating = (index) => {
    setRating(index)
    const ratingDetails = {
      libraryId: specificLibrary.id,
      rating: index,
      comment: `Rating ${index}`
    };
    (async () => dispatch(libraryRatingAction(ratingDetails)))();
  };

  const handleGradesOnChange = (e, position) => {
    const { value, checked } = e.target;
    let selectedItems = checked
      ? appendItemToArray(selectedGrades, value)
      : removeItemFromArray(selectedGrades, value);
    setSelectedGrades(selectedItems);
    setWorksheetInfo({
      ...worksheetInfo,
      gradeIds: selectedItems,
    });
  };

  const handleUploadSheets = async (e, courseMaterial) => {
    e.preventDefault();
    await dispatch(uploadWorksheetAction(e.target.files, params.libraryId, courseMaterial));
    await dispatch(getUncategorizedWorksheetsAction(params.libraryId));
  };

  const [courseMaterial, setCourseMaterial] = useState("");
  const [previewChecked, setPreviewChecked] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const saveFileDetails = (e) => {
    e.preventDefault();
    if (worksheetInfo.title && worksheetInfo.description && worksheetInfo.subjectId &&
        worksheetInfo?.gradeIds?.length > 0 && worksheetInfo?.chapterIds?.length > 0 &&
        worksheetInfo?.topicIds?.length > 0 && worksheetInfo.courseMaterialId &&
        worksheetInfo.keywords?.length > 0 && selectedFileId !== null) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
    if (formFilled) {
      const modifiedData = {
        ...worksheetInfo,
        libraryId: params.libraryId,
        worksheetFileId: selectedFileId,
        worksheetAnswersFileId: null,
        chapterIds: worksheetInfo.chapterIds.map(chapter => chapter.value),
        topicIds: worksheetInfo.topicIds.map(topic => topic.value),
        keywords: [
          ...worksheetInfo.keywords.includes(';')
              ? worksheetInfo.keywords.split(';').map(keyword => keyword.trim())  // Split and trim if there are semicolons
              : [worksheetInfo.keywords.trim()]  // Convert to array if no semicolon
        ]
      };
      dispatch(createWorksheetAction(modifiedData)).then(() => {
        (async() => dispatch(getUncategorizedWorksheetsAction(params.libraryId)))();
        setPdfFiles(uncategorizedWorksheets);
        setWorksheetInfo({});
        setSelectedChapters([]);
        setSelectedTopics([]);
        setFormKey(prevKey => prevKey + 1);
        setSelectedFileId(null);
      });
    }
  };

  const handleDiscard = () => {
    setWorksheetInfo({});
    setSelectedChapters([]);
    setSelectedTopics([]);
    setFormKey(prevKey => prevKey + 1);
    setSelectedFileId(null)
  }

  console.log("specificLibrary-->", specificLibrary);
  console.log("Libraries-->", libraries);

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar />
      <div className="mt-0 mx-0 lg:ml-20 min-h-screen">
        <PersonalLibraryHeader
          name={getFullName(userDetails.firstName, userDetails.lastName)}
          username={userDetails.username}
          location={'Nairobi, KE'}
          page={'timeline'}
          noOfFollowers={specificLibrary?.numberOfFollowers || 0}
          curriculumLevel={specificLibrary?.curriculumLevel?.level}
          noOfUploads={specificLibrary?.worksheetCount}
          handleRating={handleRating}
          rating={rating}
          libraryType={specificLibrary?.libraryType?.toLowerCase()}
        />
        <div className="flex justify-center">
          <div className="w-full max-w-8xl mx-4 md:mx-8 lg:mx-10 pt-2 md:pt-4 lg:pt-6 pb-2 md:pb-4 lg:pb-6 text-sm mt-4 flex sm:gap-4 xl:gap-6">
            <div className="flex w-full">
              <div className="w-full grid auto-rows-max grid-cols-auto-fill-200 gap-4 ease-in-out transition duration-700">
                <div className="surface surface-hover borders w-full h-56 rounded">
                  <label htmlFor="fileInput">
                    <div className="flex gap-4 flex-col justify-center items-center h-full cursor-pointer">
                      <div className="flex justify-center items-center bg-primary-main-700 w-16 h-16 rounded-full mx-auto">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 64 64"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mx-auto my-auto"
                        >
                          <g clipPath="url(#clip0_52244_11701)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M36.5714 0H27.4286V27.4286H0V36.5714H27.4286V64H36.5714V36.5714H45.7143V27.4286H36.5714V0ZM54.8571 27.4286V36.5714H64V27.4286H54.8571Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_52244_11701">
                              <rect width="64" height="64" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <h6 className="text-base">Upload files...</h6>
                    </div>
                  </label>
                  <input
                    id={"fileInput"}
                    type={"file"}
                    style={{ display: "none" }}
                    onChange={(e) => handleUploadSheets(e, 'WORKSHEET')
                    }
                  />
                </div>
                {pdfFiles && pdfFiles?.length > 0 &&
                  pdfFiles.map((file) => (
                    <div
                      className={`group surface surface-hover relative cursor-pointer ${
                        selectedFileId === file.id
                          ? "border-2 border-i-purple-500"
                          : "border-1 borders"
                      } w-full h-56 rounded surface overflow-hidden`}
                      key={file.id}
                      onClick={() => setSelectedFileId(file.id)}
                    >
                      <div className="surface h-auto overflow-hidden">
                        <PreviewPdf
                          file={file?.fileUrl}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="surface flex flex-col justify-between group-hover:bg-app-colors-surface-h dark:group-hover:bg-app-colors-surface-d-h absolute bottom-0 min-h-2-lines w-full p-2">
                        <h6 className="text-hover-active text-sm line-clamp-2">
                          {cleanWorksheetName(file?.fileName)}
                        </h6>
                        <div className="flex items-center justify-between">
                          <p className="smaller uppercase pt-2">worksheet</p>
                          <svg
                            className=" fileDescription-tile stroke-1 fill-current text-app-colors-icon dark:text-app-colors-icons-d hover:text-secondary-red-500 dark:hover:text-secondary-red-500"
                            width="28"
                            height="21"
                            viewBox="0 0 72 65"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_52244_11717)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M53.3333 7.61111V0.5H46.2222H24.8889H17.7778V7.61111H0V14.7222H7.11111V57.3889C7.11111 61.3142 10.2969 64.5 14.2222 64.5H24.8889V57.3889H14.2222V14.7222H17.7778H24.8889V7.61111H46.2222V14.7222H53.3333H56.8889V57.3889H46.2222V64.5H56.8889C60.8142 64.5 64 61.3142 64 57.3889V14.7222H71.1111V7.61111H53.3333ZM32 64.5H39.1111V57.3889H32V64.5ZM32 21.8333H24.8889V50.2778H32V21.8333ZM46.2222 21.8333H39.1111V50.2778H46.2222V21.8333Z"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_52244_11717">
                                <rect
                                  width="71.1111"
                                  height="64"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div
                id="file-attributes"
                className=" sm:sticky sm:top-20 lg:top-24"
              >
                <div className="relative hidden sm:flex sm:flex-col sm:w-88 md:w-96 xl:w-100 h-full">
                  <div className=" surface rounded-t-md sticky pt-12 pb-6 lg:pt-14 lg:pb-8 px-4 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
                    <h6 className="text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                      File Attributes
                    </h6>
                    <p className="smaller text-xs">
                      Select a file to assign attributes then click the publish
                      button.
                    </p>
                  </div>
                  <div
                    className=" relative flex flex-col rounded-b-md h-full overflow-y-auto"
                    style={{ maxHeight: "100vh" }}
                  >
                    {!formFilled ? <div className="text-xs text-white text-center bg-ired mb-2 py-1">Please fill in the missing fields</div> : null}
                    <div className="surface flex flex-col gap-6 p-6" key={formKey}>
                      <BorderedTextInput
                        type={"text"}
                        name={"title"}
                        borderText={`Title ${!formFilled && !worksheetInfo.title ? "*" : ""}`}
                        value={worksheetInfo?.title || ""}
                        handleInputChange={handleChange}
                      />
                      <BorderedTextArea
                        name={"description"}
                        borderText={`Description ${!formFilled && !worksheetInfo.description ? "*" : ""}`}
                        value={worksheetInfo?.description || ""}
                        handleInputChange={handleChange}
                      />
                      <BorderSelectInput
                        id={"subjectId"}
                        name={"subjectId"}
                        title={"subject"}
                        options={curriculumSubjects && curriculumSubjects.length > 0 ? curriculumSubjects : []}
                        handleInputChange={handleChange}
                        borderText={`Select Subject ${!formFilled && !worksheetInfo.subjectId ? "*" : ""}`}
                      />
                      <BorderSelectInput
                        id={"courseMaterialId"}
                        className={"w-full"}
                        name={"courseMaterialId"}
                        title={"course material"}
                        options={courseMaterials && courseMaterials.length > 0 ? courseMaterials : []}
                        handleInputChange={(e) => {
                          handleChange(e);
                          setCourseMaterial(e.target.value);
                        }}
                        borderText={`Course Material ${!formFilled && !worksheetInfo.courseMaterialId ? "*" : ""}`}
                      />
                      <div>
                        <p className={`text-sm ${!formFilled && (!worksheetInfo?.gradeIds || worksheetInfo?.gradeIds?.length === 0) ? "* text-i-orange-500" : ""}`}>Select Grade(s)</p>
                        <div className="flex justify-between mt-2">
                          {grades && grades.length > 0 ? (
                              grades.map((grade, index) => (
                                <div
                                  className="flex items-center mb-4"
                                  key={index + 1}
                                >
                                  <input
                                    id={grade.name.replace(/\s+/g, "_")}
                                    type="checkbox"
                                    value={grade.id}
                                    className="w-6 h-6 text-primary-main-700 bg-white borders rounded focus:ring-0 focus:outline-none"
                                    onChange={(e) =>
                                        handleGradesOnChange(e, index)
                                    }
                                  />
                                  <label
                                    htmlFor={grade.name.replace(/\s+/g, "_")}
                                    className="ml-2 text-sm font-medium text-gray-900"
                                  >
                                    {grade.name}
                                  </label>
                                </div>
                              ))
                            ) : (
                              <div className="small">There are no grades</div>
                          )
                            }
                        </div>
                      </div>
                      <BorderedDropdownTag
                        id={"chapterIds"}
                        options={chapterOptions}
                        name={"chapterIds"}
                        borderText={`Tag Chapters / Strands ${!formFilled && (!worksheetInfo.chapterIds || worksheetInfo?.chapterIds?.length === 0) ? "*" : ""}`}
                        handleInputChange={(e, item) => handleSelectChange(e, "chapterIds", item)}
                      />
                      <BorderedDropdownTag
                        id={"topicIds"}
                        options={topicOptions}
                        name={"topicIds"}
                        borderText={`Tag Topics / Sub Strands ${!formFilled && (!worksheetInfo.topicIds || worksheetInfo?.topicIds?.length === 0) ? "*" : ""}`}
                        handleInputChange={(e, item) => handleSelectChange(e, "topicIds", item)}
                      />
                      <BorderedTextArea
                        name={"keywords"}
                        borderText={`Keywords ${!formFilled && !worksheetInfo.description ? "*" : ""}`}
                        value={worksheetInfo?.keywords || ""}
                        handleInputChange={handleChange}
                        placeholder={"Type the keywords separated by semi-colon (;)"}
                      />
                      {courseMaterial === "Worksheet - Questions" && (
                        <div className="group">
                          <label
                            htmlFor="fileInput"
                            className=" flex gap-2 items-center text-gray-500 dark:text-gray-300 hover:text-secondary-blue-600 dark:hover:text-secondary-blue-600 cursor-pointer"
                          >
                            <svg
                              className="stroke-1 fill-current text-app-colors-icon dark:text-app-colors-icons-d"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M3.84824 16C2.9214 16 2.02678 15.5989 1.21754 14.7898C-0.527346 13.0432 -0.386479 10.8242 1.57397 8.85473L9.55175 0.891273C10.0038 0.453523 10.5747 0.158518 11.1932 0.0428902C11.8081 -0.0676357 12.7189 -0.0176544 13.5797 0.843182C14.9592 2.22094 14.5707 3.84635 13.5797 4.84639L5.5593 12.8509C5.33476 13.0773 4.80538 13.6084 4.07288 13.6101C3.59547 13.5936 3.14518 13.3837 2.82529 13.029C2.52765 12.7472 2.3567 12.3569 2.35113 11.9472C2.35113 11.4392 2.61308 10.9205 3.12815 10.4073L7.34874 6.16696C7.48513 6.03978 7.67809 5.99303 7.85759 6.04339C8.03724 6.09374 8.17761 6.2341 8.22796 6.41376C8.27844 6.5934 8.23157 6.78623 8.10439 6.92274L3.86788 11.1627C3.46508 11.5655 3.40451 11.8275 3.40451 11.9487C3.40949 12.0748 3.46779 12.1928 3.56489 12.2731C3.69046 12.4234 3.86936 12.5192 4.06394 12.5405C4.35088 12.5405 4.65039 12.2392 4.79472 12.0949L12.8151 4.09039C13.1074 3.7981 13.9736 2.75006 12.8151 1.59521C12.4523 1.19066 11.9073 1.00021 11.3715 1.09069C10.9698 1.17063 10.599 1.36284 10.302 1.64506L2.32268 9.61742C1.43152 10.5086 0.196348 12.2713 1.96625 14.0411C3.60958 15.6844 5.25303 14.7308 6.34025 13.6419L10.5713 9.39103C10.6713 9.28983 10.8074 9.23264 10.9497 9.23177C11.0919 9.2309 11.2285 9.2866 11.3297 9.38656C11.4309 9.48652 11.4882 9.62265 11.489 9.76489C11.4898 9.90712 11.4341 10.0439 11.3342 10.1449L7.09769 14.3958C6.02651 15.4651 4.91769 16 3.84824 16Z" />
                            </svg>
                            Attach answer sheet
                          </label>
                        </div>
                      )}

                      <div className="flex gap-4 items-center h-5">
                        <input
                          id="previewWorksheet"
                          aria-describedby="previewWorksheet"
                          type="checkbox"
                          className="w-6 h-6 text-secondary-blue-600 rounded focus:ring-0 focus:outline-none"
                          onChange={(e) => setPreviewChecked(e.target.checked)}
                        />
                        <label
                          htmlFor="previewWorksheet"
                          className="text-gray-500 dark:text-gray-300 hover:text-secondary-blue-600 dark:hover:text-secondary-blue-600 cursor-pointer"
                        >
                          Preview Worksheet
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-between pt-6">
                      <button className="pill-button outline-pill-button primary-light-button cursor-pointer"
                              onClick={handleDiscard}
                      >
                        Discard
                      </button>
                      {previewChecked ? (
                        <button className="pill-button primary-button cursor-pointer">
                          Preview
                        </button>
                      ) : (
                        <button className="pill-button primary-button cursor-pointer" onClick={saveFileDetails}>
                          Publish
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
