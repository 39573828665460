import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  UPLOAD_IMAGE_LOADING,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const uploadImageLoading = () => ({
  type: UPLOAD_IMAGE_LOADING
});

export const uploadImageSuccess = payload => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload
});

export const uploadImageFailure = error => ({
  type: UPLOAD_IMAGE_FAILURE,
  error
});

export const uploadImageAction = (files, id) => async dispatch => {
  dispatch(uploadImageLoading());
  const file = files[0];
  const data = new FormData();
  data.append('file', file)
  let fileName = null;
  if (file instanceof Object) {
    let unfile_name = file.name
    if (unfile_name.includes('.')) {
      unfile_name = unfile_name.split('.')
      unfile_name.pop()
      unfile_name = unfile_name.join('')
    }
    let mimeType = file.type
    mimeType = mimeType.split('/')[1]
    fileName = `${unfile_name}.${mimeType}`
  }
  data.append('upload_preset', 'infobrix')

  axios.post(`${CLIENT_API_URL}/files/upload`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      console.log('image data-->', res.data)
      dispatch(uploadImageSuccess({id: id, fileName: fileName, assetUrl: res.data.url}));
    })
    .catch(error => {
      console.log({error});
      dispatch(uploadImageFailure(error));
    })
}