import {
  UPDATE_LIBRARY_FAILURE,
  UPDATE_LIBRARY_LOADING,
  UPDATE_LIBRARY_SUCCESS
} from './constants'

const initState = {
  loading: false,
  result: {data: {}, msg: "", success: false},
  error: null
}

export default function updateLibrary(state=initState, action) {
  const { type, result, error } = action

  switch(type) {
    case UPDATE_LIBRARY_LOADING:
      return { ...state, loading: true }

    case UPDATE_LIBRARY_SUCCESS:
      return {
        ...state,
        loading: false,
        result
      }

    case UPDATE_LIBRARY_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state;
  }
}