import axios from 'axios';

import {
  CREATE_WORKSHEET_FAILURE,
  CREATE_WORKSHEET_LOADING,
  CREATE_WORKSHEET_SUCCESS
} from './constants'
import {getJwtTokenCookie} from '../../../utils/cookieHelper';
import {CLIENT_API_URL} from '../../../utils/constants';

export const createWorksheetLoading = () => {
  return {
    type: CREATE_WORKSHEET_LOADING
  }
}

export const createWorksheetSuccess = message => {
  return {
    type: CREATE_WORKSHEET_SUCCESS,
    message
  }
}

export const createWorksheetFailure = error => {
  return {
    type: CREATE_WORKSHEET_FAILURE,
    error
  }
}

export const createWorksheetAction = ({
                                        libraryId, title, description, subjectId, courseMaterialId, gradeIds,
                                        chapterIds, topicIds, keywords, worksheetFileId, worksheetAnswersFileId
}) => async dispatch => {
  const worksheetDetails = {
    libraryId,
    title,
    description,
    subjectId,
    courseMaterialId,
    gradeIds,
    chapterIds,
    topicIds,
    keywords,
    worksheetFileId,
    worksheetAnswersFileId
  }

  dispatch(createWorksheetLoading())
  console.log('Worksheet details -->', worksheetDetails)
  axios.post(`${CLIENT_API_URL}/worksheets`, worksheetDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        console.log('New Parent -->', res);
        dispatch(createWorksheetSuccess(['Worksheet created successfully', res.data]));
      })
      .catch(error => {
        dispatch(createWorksheetFailure({
          message: 'An error occurred while creating worksheet'
        }))
      })
}