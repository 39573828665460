const ROUTES = {
  home: '/',
  pageNotFound: '/page_not_found',
  register: '/register',
  accountType: '/select_account',
  studentSignUpPersonalInfo: '/signup/student/personal_info',
  studentSignUpSelectSchool: '/signup/student/select_school',
  studentSignUpAuthentication: '/signup/student/authentication',
  parentSignUpPersonalInfo: '/signup/parent/personal_info',
  parentSignUpSelectSchool: '/signup/parent/select_school',
  parentSignUpAuthentication: '/signup/parent/authentication',
  teacherSignUpPersonalInfo: '/signup/teacher/personal_info',
  teacherSignUpSelectSchool: '/signup/teacher/select_school',
  teacherSignUpAuthentication: '/signup/teacher/authentication',
  schoolSignUpSchoolDetails: '/signup/school/school_details',
  schoolSignUpAuthentication: '/signup/school/authentication',
  accountSetUpConnections: '/account_setup/connections',
  accountSetUpSubjects: '/account_setup/subjects',
  accountSetUpLibraries: '/account_setup/libraries',
  userSpaceTimeline: '/user_space/timeline',
  userSpaceLibrary: '/user_space/library',
  userSpaceLibrarySubjects: '/user_space/:libraryId/my_workspace/subjects/:subjectId',
  myLibrarySpace: '/user_space/:libraryId/my_library_space',
  personalLibrary: '/user_space/:libraryId/my_library_space/personal_library',
  updateLibraryWorksheets: '/user_space/:libraryId/my_library_space/personal_library/update_library_worksheets',
  libraryWorksheets: '/user_space/library_worksheets',
  viewLibraryWorksheet: '/user_space/:libraryId/my_workspace/subjects/:subjectId/worksheet/:worksheetId',
  accountSettings: '/account/settings',
  myWorkspace: '/user_space/:libraryId/my_workspace',
  communityVideos: '/community/videos',
  singleVideoPage: '/community/videos/:videoId',
  developmentTestScroll: '/development/test-scroll',
  workspaceLibraries: '/user_space/workspace_libraries',
}

export default ROUTES;
