// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestScroll = () => {
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => loadMoreComments())();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const loadMoreComments = async () => {
    try {
      const response = await axios.get('https://jsonplaceholder.typicode.com/comments', {
        params: { _page: page, _limit: 20 },
      });
      setComments((prevComments) => [...prevComments, ...response.data]);
      setPage((prevPage) => prevPage + 1);
      if (response.data.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || !hasMore) {
      return;
    }
    (async () => loadMoreComments())();
  };

  return (
      <div className="max-w-2xl mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Infinite Scroll</h1>
        <ul className="space-y-4">
          {comments.map((comment, index) => (
              <li key={index + 1} className="p-4 border rounded shadow">
                <p className="font-semibold">{comment.name}</p>
                <p>{comment.body}</p>
              </li>
          ))}
        </ul>
        {hasMore && (
            <div className="text-center my-4">
              <p>Loading more comments...</p>
            </div>
        )}
      </div>
  );
};

export default TestScroll;
