import React from 'react';
import AppRoutes from './appRoutes';
import reportWebVitals from './reportWebVitals';
import 'flowbite';
import { AuthProvider } from './utils/context/AuthProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import configStore from './store';
import {Provider} from 'react-redux';
import './App.css';
import PageLayout from './PageLayout';
import { PersistGate } from 'redux-persist/integration/react';
import {createRoot} from 'react-dom/client';

const { store, persistor } = configStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PageLayout>
          <BrowserRouter future={{ v7_relativeSplatPath: true }}>
            <AuthProvider>
              <Routes>
                <Route path="/*" element={<AppRoutes />} />
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </PageLayout>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
