import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_CHAPTERS_LOADING,
  GET_CHAPTERS_FAILURE,
  GET_CHAPTERS_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getChaptersLoading = () => ({
  type: GET_CHAPTERS_LOADING
});

export const getChaptersSuccess = payload => ({
  type: GET_CHAPTERS_SUCCESS,
  payload
});

export const getChaptersFailure = error => ({
  type: GET_CHAPTERS_FAILURE,
  error
});

export const getChaptersAction = (subjectId, gradeIds) => async dispatch => {
  console.log("Chapters action hit---!!!")
  dispatch(getChaptersLoading());

  if (gradeIds.length === 0) {
    dispatch(getChaptersSuccess([]));
    return;
  }

  axios.get(`${CLIENT_API_URL}/strands/subject/${subjectId}?gradeIds=${gradeIds}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      console.log('Chapters action success --');
      dispatch(getChaptersSuccess(res.data));
    })
    .catch(error => {
      console.log('Topics action failed --');
      dispatch(getChaptersFailure({
        message: 'An error occurred while fetching chapters'
      }))
    })
}