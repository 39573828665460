import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_UNCATEGORIZED_WORKSHEETS_LOADING,
  GET_UNCATEGORIZED_WORKSHEETS_FAILURE,
  GET_UNCATEGORIZED_WORKSHEETS_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getUncategorizedWorksheetsLoading = () => ({
  type: GET_UNCATEGORIZED_WORKSHEETS_LOADING
});

export const getUncategorizedWorksheetsSuccess = payload => ({
  type: GET_UNCATEGORIZED_WORKSHEETS_SUCCESS,
  payload
});

export const getUncategorizedWorksheetsFailure = error => ({
  type: GET_UNCATEGORIZED_WORKSHEETS_FAILURE,
  error
});

export const getUncategorizedWorksheetsAction = (libraryId) => async dispatch => {
  dispatch(getUncategorizedWorksheetsLoading());

  axios.get(`${CLIENT_API_URL}/files/library/uncategorized?libraryId=${libraryId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      dispatch(getUncategorizedWorksheetsSuccess(res.data));
    })
    .catch(error => {
      dispatch(getUncategorizedWorksheetsFailure({
        message: 'An error occurred while fetching uncategorized worksheets'
      }))
    })
}