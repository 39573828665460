import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  UPLOAD_WORKSHEET_LOADING,
  UPLOAD_WORKSHEET_FAILURE,
  UPLOAD_WORKSHEET_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const uploadWorksheetLoading = () => ({
  type: UPLOAD_WORKSHEET_LOADING
});

export const uploadWorksheetSuccess = payload => ({
  type: UPLOAD_WORKSHEET_SUCCESS,
  payload
});

export const uploadWorksheetFailure = error => ({
  type: UPLOAD_WORKSHEET_FAILURE,
  error
});

export const uploadWorksheetAction = (files, libraryId, courseMaterial) => async dispatch => {
  dispatch(uploadWorksheetLoading());
  const file = files[0];
  const data = new FormData();
  data.append('file', file)

  await axios.post(`${CLIENT_API_URL}/files/library?libraryId=${libraryId}&fileCategory=${courseMaterial}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      console.log('image data-->', res.data);
      dispatch(uploadWorksheetSuccess({
        fileName: res.data.data.fileName,
        assetUrl: res.data.data.fileUrl,
        message: 'Worksheet uploaded successfully'
      }));
    })
    .catch(error => {
      console.log({error});
      dispatch(uploadWorksheetFailure(error));
    })
}