import {
  CREATE_WORKSHEET_FAILURE,
  CREATE_WORKSHEET_LOADING,
  CREATE_WORKSHEET_SUCCESS
} from './constants'

const initialState = {
  loading: false,
  message: [],
  error: null
};

export default function createWorksheet(state = initialState, action) {
  const { type, message, error } = action;

  switch(type) {
    case CREATE_WORKSHEET_LOADING:
      return { ...state, loading: true };
    case CREATE_WORKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        message
      }
    case CREATE_WORKSHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}
