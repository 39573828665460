import React, { useState } from "react";
import BorderedTextInput from "../../../../../components/common/borderedTextInput";

const Tokens = () => {
  const [userInfo, setUserInfo] = useState({});

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const [showbalance, setShowBalance] = useState(false);

  const handleShowBalance = () => {
    setShowBalance((prevState) => !prevState);
  };

  return (
    <div className="relative">
      <div className="surface rounded-md w-full">
        <div className="pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
          <h6>Tokens</h6>
        </div>
        <div className="px-6 py-8 flex flex-col gap-4">
          <div className="small">
            You can use your t-Coins for in-app purchases. 1 t-Coin is worth
            Ksh.1
          </div>
          <div className="w-full flex gap-10">
            <div className="flex flex-col w-72 min-w-72">
              <div className="flex gap-4 pb-8 items-end flex-col border-t border-app-colors-border dark:border-app-colors-border-d pt-8">
                <div className="w-full bg-gradient-to-r from-primary-main-700 to-secondary-red-500 py-3 px-6 rounded">
                  <div className="flex items-center">
                    <img
                      src="/images/icon-diamond.png"
                      alt="icon"
                      width={16}
                      height={18}
                      className="mb-1"
                    />
                    <p className="text-white ml-2 font-semibold">
                      t-Coin Balance
                    </p>
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    <button
                      id="show-hide"
                      type="button"
                      onClick={handleShowBalance}
                    >
                      {showbalance ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 64 64"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M35.2008 48H28.8008V54.4H35.2008V48Z"
                            fill="white"
                          />
                          <path
                            d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z"
                            fill="white"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <div className="flex gap-2 text-white mt-2">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 64 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_61712_221726)">
                              <path
                                d="M35.2008 48H28.8008V54.4H35.2008V48Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                                fill="white"
                              />
                              <path
                                d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z"
                                fill="white"
                              />
                              <path
                                d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_61712_221726">
                                <rect width="64" height="64" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>{" "}
                          show balance
                        </div>
                      )}
                    </button>
                    <title
                      id="t-coinsBal"
                      className={`text-white text-2xl ${showbalance ? "" : "hidden"}`}
                    >
                      2,358
                    </title>
                  </div>
                </div>
                <button className="pill-button primary-button max-w-max items-end">
                  Purchase t-Coins
                </button>
              </div>
              <div className="flex gap-4 flex-col py-6 border-b border-app-colors-border dark:border-app-colors-border-d">
                <p className="small">Transfer</p>
                <div className="flex flex-col gap-4">
                  <BorderedTextInput
                    id={"username"}
                    name={"username"}
                    type={"text"}
                    borderText={"Username"}
                    handleInputChange={handleChange}
                    value={userInfo.username || ""}
                  />
                  <button className="pill-button yellow-button max-w-max  place-self-end">
                    Transfer Tokens
                  </button>
                </div>
              </div>
              <div className="flex gap-4 flex-col py-6">
                <p className="small">Withdraw</p>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-2">
                    {/*<input
                      type="radio"
                      className="w-4 h-4 cursor-pointer text-primary-main-700 bg-white border-gray-300 focus:ring-primary-main-700 focus:ring-1"
                    />
                    <svg
                      width="100"
                      height="40"
                      viewBox="0 0 512 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M120.326 64.6116C133.484 67.9196 144.527 50.9126 144.527 50.9126C155.406 67.9026 164.21 54.2196 164.21 54.2196C164.21 54.2196 156.093 67.9196 130.234 67.3766L120.326 64.6116Z"
                        fill="#9C4C44"
                      />
                      <path
                        d="M171.167 36.4105C171.167 36.4105 152.396 75.0845 120.14 64.8425C120.14 64.8425 179.022 82.9935 200.603 50.6085L171.167 36.4105Z"
                        fill="#E14445"
                      />
                      <path
                        d="M189.623 63.6449V116.765H129.395V52.4409C127.845 52.0799 126.506 51.6309 125.543 51.2689V123.258C125.543 128.708 129.332 133.125 134.009 133.125H185.009C189.684 133.125 193.474 128.708 193.474 123.258V60.9909C192.211 61.9299 190.727 62.9249 189.623 63.6449Z"
                        fill="#ABB4A7"
                      />
                      <path
                        d="M129.393 10.9003H189.623V42.9863L193.473 45.2113V10.0183C193.473 4.56733 189.684 0.150326 185.009 0.150326H134.007C129.332 0.150326 125.543 4.56733 125.543 10.0183V55.0853C127.261 54.8453 128.566 54.4373 129.393 54.1163V10.9003Z"
                        fill="#ABB4A7"
                      />
                      <path
                        d="M115.258 57.3647V95.9597H91.6047V61.2257C91.6047 51.5157 87.6197 47.4067 81.3937 47.4067C74.5467 47.4067 69.5667 52.0137 69.5667 62.4697V95.9597H45.9137V61.2257C45.9137 51.5157 42.0537 47.4067 35.7027 47.4067C28.7337 47.4067 23.7537 52.0137 23.7537 62.4697V95.9597H0.0947266V28.4847H22.6317V35.5797C27.7367 30.1027 34.8337 27.3627 42.7997 27.3627C52.0117 27.3627 59.8547 30.7237 64.5887 37.9457C69.9417 31.2217 78.2817 27.3627 87.9947 27.3627C103.68 27.3627 115.258 36.4487 115.258 57.3647Z"
                        fill="#27B34A"
                      />
                      <path
                        d="M257.574 62.2195C257.574 52.0145 251.348 46.1595 243.38 46.1595C235.412 46.1595 229.186 52.0145 229.186 62.2195C229.186 72.4295 235.412 78.2815 243.38 78.2815C251.348 78.2815 257.574 72.4295 257.574 62.2195ZM281.478 62.2195C281.478 83.6365 266.662 97.0835 248.36 97.0835C240.517 97.0835 234.168 94.7155 229.561 89.9845V120.113H205.906V28.4855H228.441V35.2035C232.924 29.8525 239.521 27.3615 248.36 27.3615C266.662 27.3615 281.478 40.8035 281.478 62.2195Z"
                        fill="#27B34A"
                      />
                      <path
                        d="M311.601 55.746H339.243C337.996 48.647 332.768 44.171 325.422 44.171C318.076 44.171 312.972 48.647 311.601 55.746ZM361.026 68.2H311.976C313.967 75.043 319.818 79.03 328.534 79.03C335.132 79.03 339.488 77.163 344.097 73.175L356.546 86.123C350.072 93.347 340.611 97.083 327.788 97.083C303.758 97.083 288.197 82.267 288.197 62.095C288.197 41.803 304.009 27.362 325.296 27.362C345.217 27.362 361.401 40.059 361.401 62.345C361.401 64.089 361.154 66.331 361.026 68.2Z"
                        fill="#27B34A"
                      />
                      <path
                        d="M367.004 90.1098L374.102 73.9238C380.327 77.7818 389.415 80.1498 397.633 80.1498C405.601 80.1498 408.212 78.4058 408.212 75.5428C408.212 66.4528 368.374 76.7868 368.374 49.7708C368.374 37.0718 380.077 27.3618 401.243 27.3618C410.704 27.3618 421.162 29.3538 427.884 33.0908L420.789 49.1468C414.066 45.4108 407.343 44.1708 401.366 44.1708C393.399 44.1708 390.535 46.4098 390.535 48.8978C390.535 58.2368 430.25 48.1518 430.25 74.9198C430.25 87.3728 418.798 97.0828 396.885 97.0828C385.431 97.0828 373.603 94.2158 367.004 90.1098Z"
                        fill="#27B34A"
                      />
                      <path
                        d="M488.513 62.095C488.513 51.885 482.289 46.159 474.323 46.159C466.353 46.159 460.129 51.885 460.129 62.095C460.129 72.431 466.353 78.281 474.323 78.281C482.289 78.281 488.513 72.431 488.513 62.095ZM511.795 28.484V95.959H489.261V89.237C484.781 94.589 478.181 97.083 469.341 97.083C451.039 97.083 436.225 83.635 436.225 62.095C436.225 40.683 451.039 27.362 469.341 27.362C477.184 27.362 483.534 29.603 488.142 34.332V28.484H511.795Z"
                        fill="#27B34A"
                      />
                    </svg>*/}
                    <div className="w-full bg-gradient-to-r from-primary-main-700 to-secondary-red-500 py-3 px-6 rounded">
                      <div className="flex items-center">
                        <img
                          src="/images/icon-diamond.png"
                          alt="icon"
                          width={16}
                          height={18}
                          className="mb-1"
                        />
                        <p className="text-white ml-2 font-semibold">
                          Account Balance
                        </p>
                      </div>
                      <div className="flex gap-2 items-center mt-2">
                        <button
                          id="show-hide"
                          type="button"
                          onClick={handleShowBalance}
                        >
                          {showbalance ? (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M35.2008 48H28.8008V54.4H35.2008V48Z"
                                fill="white"
                              />
                              <path
                                d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                                fill="white"
                              />
                            </svg>
                          ) : (
                            <div className="flex gap-2 text-white mt-2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 64 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_61712_221726)">
                                  <path
                                    d="M35.2008 48H28.8008V54.4H35.2008V48Z"
                                    fill="white"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                                    fill="white"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_61712_221726">
                                    <rect width="64" height="64" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>{" "}
                              show balance
                            </div>
                          )}
                        </button>
                        <title
                          id="t-accountBal"
                          className={`text-white text-2xl ${showbalance ? "" : "hidden"}`}
                        >
                          150,021
                        </title>
                      </div>
                    </div>
                  </div>

                  <button className="pill-button blue-button max-w-max place-self-end">
                    Withdraw Cash
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <div className="small">Statistics</div>

              <div className="flex justify-between flex-wrap my-4">
                <div className="flex items-center gap-2 my-2">
                  <span>
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20.3194"
                        cy="20.1094"
                        r="20.1094"
                        fill="#EFECFD"
                      />
                      <path
                        d="M11.1788 15.3563C11.1788 14.5486 11.8335 13.8938 12.6413 13.8938H14.1038C14.9115 13.8938 15.5663 14.5486 15.5663 15.3563V16.8188C15.5663 17.6265 14.9115 18.2813 14.1038 18.2813H12.6413C11.8335 18.2813 11.1788 17.6265 11.1788 16.8188V15.3563Z"
                        fill="#7468E4"
                      />
                      <path
                        d="M11.1788 22.6688C11.1788 21.8611 11.8335 21.2063 12.6413 21.2063H14.1038C14.9115 21.2063 15.5663 21.8611 15.5663 22.6688V24.1313C15.5663 24.939 14.9115 25.5938 14.1038 25.5938H12.6413C11.8335 25.5938 11.1788 24.939 11.1788 24.1313V22.6688Z"
                        fill="#7468E4"
                      />
                      <path
                        d="M25.8038 17.5501C24.996 17.5501 24.3413 18.2049 24.3413 19.0126V20.4751C24.3413 21.2828 24.996 21.9376 25.8038 21.9376H27.2663C28.074 21.9376 28.7288 21.2828 28.7288 20.4751V19.0126C28.7288 18.2049 28.074 17.5501 27.2663 17.5501H25.8038Z"
                        fill="#7468E4"
                      />
                      <path
                        d="M17.7601 15.3564C17.3562 15.3564 17.0289 15.6838 17.0289 16.0877C17.0289 16.4915 17.3562 16.8189 17.7601 16.8189H18.4914C18.8952 16.8189 19.2226 17.1463 19.2226 17.5502V21.9377C19.2226 22.3415 18.8952 22.6689 18.4914 22.6689H17.7601C17.3562 22.6689 17.0289 22.9963 17.0289 23.4002C17.0289 23.804 17.3562 24.1314 17.7601 24.1314H18.4914C19.7029 24.1314 20.6851 23.1492 20.6851 21.9377V20.4752H22.1476C22.5515 20.4752 22.8789 20.1478 22.8789 19.7439C22.8789 19.3401 22.5515 19.0127 22.1476 19.0127H20.6851V17.5502C20.6851 16.3386 19.7029 15.3564 18.4914 15.3564H17.7601Z"
                        fill="#7468E4"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="">23,526.00</p>
                    <p className="small font-normal">Deposits</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 my-2">
                  <span>
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20.2266"
                        cy="20.1094"
                        r="20.1094"
                        fill="#E4F8FB"
                      />
                      <path
                        d="M24.9794 16.0879H13.2794C12.4717 16.0879 11.8169 16.7427 11.8169 17.5504V26.3254C11.8169 27.1331 12.4717 27.7879 13.2794 27.7879H19.495C19.697 27.7879 19.8607 27.6242 19.8607 27.4223V24.1316C19.8607 23.3239 20.5154 22.6691 21.3232 22.6691H26.0763C26.2782 22.6691 26.4419 22.5054 26.4419 22.3035V17.5504C26.4419 16.7427 25.7871 16.0879 24.9794 16.0879Z"
                        fill="#51D5EC"
                      />
                      <path
                        d="M21.3237 24.8631C21.3237 24.4592 21.6511 24.1318 22.055 24.1318H27.1737C27.5776 24.1318 27.905 24.4592 27.905 24.8631V28.5193C27.905 28.9232 27.5776 29.2506 27.1737 29.2506H22.055C21.6511 29.2506 21.3237 28.9232 21.3237 28.5193V24.8631Z"
                        fill="#51D5EC"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="">2,150.00</p>
                    <p className="small font-normal">Withdrawals</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 my-2">
                  <span>
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20.1338"
                        cy="20.1094"
                        r="20.1094"
                        fill="#E5F7ED"
                      />
                      <path
                        d="M18.3059 24.9881C18.3059 25.7958 17.6511 26.4506 16.8434 26.4506C16.0357 26.4506 15.3809 25.7958 15.3809 24.9881C15.3809 24.1804 16.0357 23.5256 16.8434 23.5256C17.6511 23.5256 18.3059 24.1804 18.3059 24.9881Z"
                        fill="#22B573"
                      />
                      <path
                        d="M26.84 14.2087C27.1395 14.4797 27.1626 14.9421 26.8916 15.2416L25.1603 17.1552C25.0295 17.2997 25.0351 17.5213 25.1728 17.659L26.1352 18.6214C26.4208 18.907 26.4208 19.37 26.1352 19.6555C25.8496 19.9411 25.3866 19.9411 25.1011 19.6555L24.2163 18.7707C24.0684 18.6229 23.8269 18.6289 23.6866 18.784L19.9448 22.9197C19.6738 23.2192 19.2114 23.2423 18.9119 22.9713C18.6124 22.7004 18.5893 22.238 18.8603 21.9385L25.8071 14.2604C26.0781 13.9609 26.5405 13.9378 26.84 14.2087Z"
                        fill="#22B573"
                      />
                      <path
                        d="M12.6698 20.8151C12.9554 20.5296 13.4184 20.5296 13.7039 20.8151L14.8008 21.912C15.0864 22.1976 15.0864 22.6606 14.8008 22.9462C14.5153 23.2317 14.0523 23.2317 13.7667 22.9462L12.6698 21.8493C12.3842 21.5637 12.3842 21.1007 12.6698 20.8151Z"
                        fill="#22B573"
                      />
                      <path
                        d="M13.4899 18.7097L15.8092 21.0289C16.3803 21.6 17.3063 21.6 17.8774 21.0289L21.6592 17.2472C22.2303 16.6761 22.2303 15.75 21.6592 15.1789L19.3399 12.8597C18.7688 12.2886 17.8428 12.2885 17.2717 12.8597L13.4899 16.6414C12.9188 17.2125 12.9188 18.1386 13.4899 18.7097Z"
                        fill="#22B573"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="">20,110.00</p>
                    <p className="small font-normal">Purchases</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 my-2">
                  <span>
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20.1338"
                        cy="20.1094"
                        r="20.1094"
                        fill="#E5F7ED"
                      />
                      <path
                        d="M18.3059 24.9881C18.3059 25.7958 17.6511 26.4506 16.8434 26.4506C16.0357 26.4506 15.3809 25.7958 15.3809 24.9881C15.3809 24.1804 16.0357 23.5256 16.8434 23.5256C17.6511 23.5256 18.3059 24.1804 18.3059 24.9881Z"
                        fill="#22B573"
                      />
                      <path
                        d="M26.84 14.2087C27.1395 14.4797 27.1626 14.9421 26.8916 15.2416L25.1603 17.1552C25.0295 17.2997 25.0351 17.5213 25.1728 17.659L26.1352 18.6214C26.4208 18.907 26.4208 19.37 26.1352 19.6555C25.8496 19.9411 25.3866 19.9411 25.1011 19.6555L24.2163 18.7707C24.0684 18.6229 23.8269 18.6289 23.6866 18.784L19.9448 22.9197C19.6738 23.2192 19.2114 23.2423 18.9119 22.9713C18.6124 22.7004 18.5893 22.238 18.8603 21.9385L25.8071 14.2604C26.0781 13.9609 26.5405 13.9378 26.84 14.2087Z"
                        fill="#22B573"
                      />
                      <path
                        d="M12.6698 20.8151C12.9554 20.5296 13.4184 20.5296 13.7039 20.8151L14.8008 21.912C15.0864 22.1976 15.0864 22.6606 14.8008 22.9462C14.5153 23.2317 14.0523 23.2317 13.7667 22.9462L12.6698 21.8493C12.3842 21.5637 12.3842 21.1007 12.6698 20.8151Z"
                        fill="#22B573"
                      />
                      <path
                        d="M13.4899 18.7097L15.8092 21.0289C16.3803 21.6 17.3063 21.6 17.8774 21.0289L21.6592 17.2472C22.2303 16.6761 22.2303 15.75 21.6592 15.1789L19.3399 12.8597C18.7688 12.2886 17.8428 12.2885 17.2717 12.8597L13.4899 16.6414C12.9188 17.2125 12.9188 18.1386 13.4899 18.7097Z"
                        fill="#22B573"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="">20,110.00</p>
                    <p className="small font-normal">Taxes</p>
                  </div>
                </div>
              </div>

              <div className="small my-6 flex justify-between">
                <div>Recent Transactions</div>
                <div className="text-primary-600 cursor-pointer">View all </div>
              </div>
              <div>
                <div className="grid grid-flow-row-dense grid-cols-6 gap-3 items-center mb-3">
                  <div className="col-span-2 flex gap-2 items-center">
                    <img
                      className="surface object-cover w-10 h-10 rounded-full t-ring ring-2"
                      src="/images/test-avatar.png"
                      alt="avatar"
                    />
                    <div className="smaller font-normal">
                      Transferred to <span>Sandile Siyabonga</span>
                    </div>
                  </div>
                  <div className="smaller font-normal">April 12, 2023</div>
                  <div className="smaller font-normal text-center">-</div>
                  <div className="smaller font-normal">-7000</div>
                  <div className="smaller  text-green-500 font-semibold">
                    Success
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-6 gap-3 items-center mb-3">
                  <div className="col-span-2 flex gap-2 items-center">
                    <img
                      className="surface object-cover w-10 h-10 rounded-full t-ring ring-2"
                      src="/images/test-avatar.png"
                      alt="avatar"
                    />
                    <div className="smaller font-normal">
                      Transferred to <span>Sandile Siyabonga</span>
                    </div>
                  </div>
                  <div className="smaller font-normal">April 12, 2023</div>
                  <div className="smaller font-normal text-center">-</div>
                  <div className="smaller font-normal">-7000</div>
                  <div className="smaller  text-green-500 font-semibold">
                    Success
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-6 gap-3 items-center mb-3">
                  <div className="col-span-2 flex gap-2 items-center">
                    <img
                      className="surface object-cover w-10 h-10 rounded-full t-ring ring-2"
                      src="/images/test-avatar.png"
                      alt="avatar"
                    />
                    <div className="smaller font-normal">
                      Transferred to <span>Sandile Siyabonga</span>
                    </div>
                  </div>
                  <div className="smaller font-normal">April 12, 2023</div>
                  <div className="smaller font-normal text-center">-</div>
                  <div className="smaller font-normal">-7000</div>
                  <div className="smaller  text-green-500 font-semibold">
                    Success
                  </div>
                </div>
              </div>
              <div className="smaller text-center mt-3">
                <span>Please note: </span>Ticet will never ask you for your
                password, PIN, CVV or card details over the phone or via email.
                Need help?{" "}
                <a href="https://example.com" className="text-primary-600">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokens;
