import { useState, useEffect } from "react";
import Searchable from "react-searchable-dropdown";
import styled from "styled-components";

const SearchableDropDown = ({
  id,
  name,
  borderText,
  options,
  handleChange,
  hasValue = true,
  loading = false,
  bgColor = "dark:bg-app-colors-surface-d bg-app-colors-surface",
  textBg = "white",
}) => {
  const [focused, setFocused] = useState(false);

  const dark = localStorage.getItem("color-theme") === "dark";

  return (
    <div>
      <span className="block">
        <div
          className={`relative borders flex flex-col sm:h-10 lg:h-12 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 rounded ${borderText.includes("*") ? "border-secondary-red-500 focus-within:border-secondary-red-500" : ""}`}
        >
          <div className="relative">
            <span
              className={`absolute inline-flex ml-2 ${bgColor} py-0 px-1 -top-3 text-xs md:text-sm ${focused ? "text-primary-main-700" : ""} ${borderText.includes("*") ? "text-secondary-red-500" : ""}`}
            >
              {borderText}
            </span>
          </div>

          <div className="lg:py-1.5 mt-1">
            <SelectSearch className="select-search surface" dark={dark}>
              <Searchable
                value=""
                id={id}
                name={name}
                options={options}
                onSelect={(code) => handleChange(code)}
                hasValue={hasValue}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                placeholder={loading ? "Loading ..." : "Search"}
              />
            </SelectSearch>
          </div>
        </div>
      </span>
    </div>
  );
};

const SelectSearch = styled.div`
  line-height: 1rem;
  div {
    color: ${({ dark }) => (dark ? "#fff5f3" : "#0f0f0f")}; 
    background: ${({ dark }) => (dark ? "#20232b" : "#f8fafc")};/
    font-size: 0.75rem;
    border-radius: px;
    border: ${({ dark }) =>
      dark ? "0px solid #6b7280" : "0px solid #d1d5db"}; 
  }
  div:hover {
    background: ${({ dark }) => (dark ? "#343946" : "#f5f5f5")}; 
    color: ${({ dark }) => (dark ? "#fff5f3" : "#0f0f0f")}; 
  }
  input {
    color: ${({ dark }) => (dark ? "#fff5f3" : "#0f0f0f")}; 
    padding: 4px;
    padding-left: 12px;
    background: ${({ dark }) => (dark ? "#20232b" : "#f8fafc")};
    font-size: 16px;
    font-weight: 600; 
    line-height: 1.5; 
  }

  input:focus {
    outline: none;
    box-shadow: 0 0 0;
  }

  input:focus {
    box-shadow: 0 0 0;
  }
  input::placeholder {
    color: ${({ dark }) => (dark ? "#949aa8" : "#4c4c4a")}; /* Placeholder */
    opacity: 1;
    font-size: 0.75rem;
  }
  .searchable__controls__arrow svg {
    fill: #7a8196;
    height: 8px;
    width: 8px;
    margin: 5px;
  }
`;

export default SearchableDropDown;
