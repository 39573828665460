import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_GRADE_CHAPTERS_LOADING,
  GET_GRADE_CHAPTERS_FAILURE,
  GET_GRADE_CHAPTERS_SUCCESS
} from './constants';
import { getJwtTokenCookie } from '../../utils/cookieHelper';

export const getGradeChaptersLoading = () => ({
  type: GET_GRADE_CHAPTERS_LOADING
});

export const getGradeChaptersSuccess = payload => ({
  type: GET_GRADE_CHAPTERS_SUCCESS,
  payload
});

export const getGradeChaptersFailure = error => ({
  type: GET_GRADE_CHAPTERS_FAILURE,
  error
});

export const getGradeChaptersAction = (subjectId, grades) => async dispatch => {
  console.log("Chapters action hit---!!!");
  dispatch(getGradeChaptersLoading());

  if (!grades || grades.length === 0) {
    dispatch(getGradeChaptersSuccess([]));
    return;
  }

  try {
    const gradeIds = grades.map(grade => grade.id); // Extract gradeIds from grades
    const res = await axios.get(`${CLIENT_API_URL}/strands/subject/${subjectId}?gradeIds=${gradeIds}`, {
      headers: {
        'Authorization': `Bearer ${await getJwtTokenCookie()}`
      }
    });

    console.log('Chapters action success --');

    const resChapters = res?.data?.data || [];

    // Initialize an array to hold the results
    const formattedChapters = grades.map((grade, index) => ({
      grade: grade.name,
      chapters: resChapters?.map(chapter => ({
        name: chapter.title,
        topics: chapter.topics.map(topic => ({
          title: topic.title,
          id: topic.id
        })),
      })) || []
    }));

    dispatch(getGradeChaptersSuccess(formattedChapters));
  } catch (error) {
    console.log('Grade Chapters action failed --', error);
    dispatch(getGradeChaptersFailure({
      message: 'An error occurred while fetching grade chapters'
    }));
  }
}
