import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_COURSE_MATERIALS_LOADING,
  GET_COURSE_MATERIALS_FAILURE,
  GET_COURSE_MATERIALS_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getCourseMaterialsLoading = () => ({
  type: GET_COURSE_MATERIALS_LOADING
});

export const getCourseMaterialsSuccess = payload => ({
  type: GET_COURSE_MATERIALS_SUCCESS,
  payload
});

export const getCourseMaterialsFailure = error => ({
  type: GET_COURSE_MATERIALS_FAILURE,
  error
});

export const getCourseMaterialsAction = () => async dispatch => {
  console.log("CourseMaterials action hit---!!!")
  dispatch(getCourseMaterialsLoading());

  axios.get(`${CLIENT_API_URL}/course-materials`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      console.log('CourseMaterials action called --');
      console.log('CourseMaterials -->', res.data);
      dispatch(getCourseMaterialsSuccess(res.data));
    })
    .catch(error => {
      console.log('CourseMaterials action failed --');
      dispatch(getCourseMaterialsFailure({
        message: 'An error occurred while fetching course materials'
      }))
    })
}