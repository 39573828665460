import React, {useEffect, useState} from 'react';
import WorkSpacePublishedItem from '../../../../components/common/workSpacePublishedItem';
import {useDispatch, useSelector} from 'react-redux';
import {getSubjectsAction} from '../../../../commonData/subjects/action';
import {useNavigate, useParams} from 'react-router-dom';
import {getSpecificItemById} from '../../../../utils/common';

const Published = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const subjects = useSelector(state => state.getSubjects.payload.data);
  const libraries = useSelector(state => state.getLibraries.payload.data);

  const [specificLibrary, setSpecificLibrary] = useState({});
  const curriculumLevelId = specificLibrary?.curriculumLevel?.id;

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      setSpecificLibrary(getSpecificItemById(libraries, params.libraryId));
    }
  }, [libraries]);

  useEffect(() => {
    if (curriculumLevelId) {
      (async () => dispatch(getSubjectsAction(curriculumLevelId)))();
    }
  }, [dispatch, curriculumLevelId]);

  console.log('Subjects --->', subjects);

  const worksheetsRoutePage = (subjectId) => {
    const path = `/user_space/${params.libraryId}/my_workspace/subjects/${subjectId}`;
    navigate(path);
  };

  return (
    <div>
      <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        {
          subjects && subjects.length > 0
            ? subjects.map((subject, index) => (
              <WorkSpacePublishedItem
                key={index}
                item={`${subject.name} (${subject.code})`}
                noOfItems={21}
                handleClick={() => worksheetsRoutePage(subject.id)}
              />
            ))
            : (
                <div className="small">There are no subjects</div>
              )
        }
      </div>
    </div>
  );
};

export default Published;
