import React from 'react';

export default function Footer() {
  return (
    <footer className="flex flex-col gap-4 items-center sm:flex-row sm-gap-8 sm:gap-0 sm:divide-x sm:justify-center lg:justify-end w-full max-w-9xl py-8">
      
        <a href="https://example.com"><p className="smaller text-center mx-4 text-white">Official  Website</p></a>
        <a href="https://example.com"><p className="smaller text-center mx-4 text-white">Terms of Use Policy</p></a>
        <a href="https://example.com"><p className="smaller text-center mx-4 text-white">Data Protection Policy</p></a>
        <a href="https://example.com"><p className="smaller text-center mx-4 text-white">Privacy Policy</p></a>
         <p className="smaller text-center px-4 md:pl-4 text-white">&copy; 2024 ticet edu Ltd</p>
         

    </footer>
  )
}
