import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  RESET_LOGIN_MESSAGE,
} from './constants'

const initState = {
  loading: false,
  payload: [],
  error: null
}

export default function userLogin(state=initState, action) {
  const { type, payload, error } = action

  switch(type) {
    case USER_LOGIN_LOADING:
      return { ...state, loading: true }

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case RESET_LOGIN_MESSAGE:
      return {
        ...state,
        loading: false,
        payload: {}
      };

    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state;
  }
}