import React from "react";
import WorkSpaceItem from "../../../../components/common/workSpaceItem";

const Drafts = ({noOfWorksheets=0, noOfCourses=0, noOfFlashcards=0,  noOfQuizzes=0,
                  noOfExercises=0, noOfLessonPlans=0}) => {
  return (
    <div>
      <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        <WorkSpaceItem
          item={"Worksheets"}
          img={"/images/worksheet-cover.jpeg"}
          noOfItems={noOfWorksheets}
        />
        <WorkSpaceItem
          item={"Courses"}
          img={"/images/coming-soon.png"}
          noOfItems={noOfCourses}
        />
        <WorkSpaceItem
          item={"Flashcards"}
          img={"/images/coming-soon.png"}
          noOfItems={noOfFlashcards}
        />
        <WorkSpaceItem
          item={"Quizzes"}
          img={"/images/coming-soon.png"}
          noOfItems={noOfQuizzes}
        />
        <WorkSpaceItem
          item={"Exercises"}
          img={"/images/coming-soon.png"}
          noOfItems={noOfExercises}
        />

        <WorkSpaceItem
          item={"Lesson Plans & Schemes of work"}
          img={"/images/coming-soon.png"}
          noOfItems={noOfLessonPlans}
        />
      </div>
    </div>
  );
};

export default Drafts;
