import {useState} from 'react';

const StarRating = ({ rating = 1, handleRating = () => {} }) => {
  const [hoverRating, setHoverRating] = useState(0);
  return (
      <div className="flex gap-1 mt-1">
        {[...Array(5)].map((_, index) => {
          let fillColor = "#d1d5db"; // Default grey color for empty stars

          if (index < Math.floor(rating)) {
            // Fully fill stars for whole numbers
            fillColor = "#fab600";
          } else if (index < rating) {
            // Partially fill the star based on the decimal
            const decimalPart = rating - Math.floor(rating);
            return (
                <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                    viewBox="0 0 21 20"
                    fill="#d1d5db" // Default fill for empty part of the star
                    className={`cursor-pointer ${index < (hoverRating || rating) ? "text-yellow-500" : "text-app-colors-icon dark:text-app-colors-icons-d"}`}
                    onMouseEnter={() => setHoverRating(index + 1)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => handleRating(index + 1)}
                >
                  <defs>
                    {/* Mask to fill the yellow part of the star */}
                    <mask id={`partialMask${index}`}>
                      <rect x="0" y="0" width={decimalPart * 21} height="20" fill="white" />
                    </mask>
                  </defs>

                  {/* The gray part of the star */}
                  <g>
                    <path fill="#d1d5db" d="M15.5181 16.995H17.5181V18.995H15.5181V16.995Z" />
                    <path fill="#d1d5db" d="M20.425 7.44396C20.253 6.92796 19.81 6.54996 19.277 6.46796L14.084 5.66796L11.827 0.815957C11.59 0.305957 11.081 -0.019043 10.521 -0.019043C9.96303 -0.019043 9.45403 0.306957 9.21703 0.815957L6.95903 5.66796L1.76603 6.46796C1.23203 6.54996 0.789029 6.92796 0.617029 7.44396C0.445029 7.96096 0.575029 8.53096 0.952029 8.91896L4.75703 12.855L3.87403 18.312C3.78503 18.862 4.01503 19.413 4.46603 19.735C4.71403 19.91 5.00503 20 5.29603 20C5.53603 20 5.77503 19.939 5.99303 19.818L10.52 17.295L13.5 18.955V16.666L11.494 15.548L10.52 15.005L9.54603 15.548L6.03003 17.508L6.73103 13.174L6.89103 12.185L6.19503 11.464L3.11903 8.28296L7.26303 7.64496L8.32103 7.48196L8.77303 6.51196L10.522 2.75296L12.271 6.51196L12.722 7.48196L13.78 7.64496L17.926 8.28396L14.849 11.465L14.152 12.186L14.312 13.175L14.61 15.017H16.636L16.286 12.856L20.093 8.91996C20.468 8.53096 20.597 7.96096 20.425 7.44396Z" />
                  </g>

                  {/* The yellow part of the star using the mask */}
                  <g mask={`url(#partialMask${index})`}>
                    <path fill="#fab600" d="M15.5181 16.995H17.5181V18.995H15.5181V16.995Z" />
                    <path fill="#fab600" d="M20.425 7.44396C20.253 6.92796 19.81 6.54996 19.277 6.46796L14.084 5.66796L11.827 0.815957C11.59 0.305957 11.081 -0.019043 10.521 -0.019043C9.96303 -0.019043 9.45403 0.306957 9.21703 0.815957L6.95903 5.66796L1.76603 6.46796C1.23203 6.54996 0.789029 6.92796 0.617029 7.44396C0.445029 7.96096 0.575029 8.53096 0.952029 8.91896L4.75703 12.855L3.87403 18.312C3.78503 18.862 4.01503 19.413 4.46603 19.735C4.71403 19.91 5.00503 20 5.29603 20C5.53603 20 5.77503 19.939 5.99303 19.818L10.52 17.295L13.5 18.955V16.666L11.494 15.548L10.52 15.005L9.54603 15.548L6.03003 17.508L6.73103 13.174L6.89103 12.185L6.19503 11.464L3.11903 8.28296L7.26303 7.64496L8.32103 7.48196L8.77303 6.51196L10.522 2.75296L12.271 6.51196L12.722 7.48196L13.78 7.64496L17.926 8.28396L14.849 11.465L14.152 12.186L14.312 13.175L14.61 15.017H16.636L16.286 12.856L20.093 8.91996C20.468 8.53096 20.597 7.96096 20.425 7.44396Z" />
                  </g>
                </svg>
            );
          }

          return (
              <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  width="20"
                  viewBox="0 0 21 20"
                  fill={fillColor}
              >
                <g clipPath="url(#clip0_63822_481)">
                  <path d="M15.5181 16.995H17.5181V18.995H15.5181V16.995Z" />
                  <path d="M20.425 7.44396C20.253 6.92796 19.81 6.54996 19.277 6.46796L14.084 5.66796L11.827 0.815957C11.59 0.305957 11.081 -0.019043 10.521 -0.019043C9.96303 -0.019043 9.45403 0.306957 9.21703 0.815957L6.95903 5.66796L1.76603 6.46796C1.23203 6.54996 0.789029 6.92796 0.617029 7.44396C0.445029 7.96096 0.575029 8.53096 0.952029 8.91896L4.75703 12.855L3.87403 18.312C3.78503 18.862 4.01503 19.413 4.46603 19.735C4.71403 19.91 5.00503 20 5.29603 20C5.53603 20 5.77503 19.939 5.99303 19.818L10.52 17.295L13.5 18.955V16.666L11.494 15.548L10.52 15.005L9.54603 15.548L6.03003 17.508L6.73103 13.174L6.89103 12.185L6.19503 11.464L3.11903 8.28296L7.26303 7.64496L8.32103 7.48196L8.77303 6.51196L10.522 2.75296L12.271 6.51196L12.722 7.48196L13.78 7.64496L17.926 8.28396L14.849 11.465L14.152 12.186L14.312 13.175L14.61 15.017H16.636L16.286 12.856L20.093 8.91996C20.468 8.53096 20.597 7.96096 20.425 7.44396Z" />
                </g>
              </svg>
          );
        })}
      </div>
  );
};

export default StarRating;
