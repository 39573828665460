import axios from 'axios';

import {
  GET_SUGGESTED_LIBRARIES_LOADING,
  GET_SUGGESTED_LIBRARIES_SUCCESS,
  GET_SUGGESTED_LIBRARIES_FAILURE
} from './constants';
import {CLIENT_API_URL} from '../../utils/constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getSuggestedLibrariesLoading = () => ({
  type: GET_SUGGESTED_LIBRARIES_LOADING
})

export const getSuggestedLibrariesSuccess = payload => ({
  type: GET_SUGGESTED_LIBRARIES_SUCCESS,
  payload
})

export const getSuggestedLibrariesFailure = error => ({
  type: GET_SUGGESTED_LIBRARIES_FAILURE,
  error
})

export const getSuggestedLibrariesAction = (libraryId, page, size) => async dispatch => {
  dispatch(getSuggestedLibrariesLoading())

  axios.get(`${CLIENT_API_URL}/libraries/suggested?libraryId=${libraryId}&page=${page}&size=${size}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    console.log('Suggested Libraries -->', res.data);
    dispatch(getSuggestedLibrariesSuccess(res.data));
  })
  .catch(error => {
    dispatch(getSuggestedLibrariesFailure({
      message: 'An error occurred while fetching grades'
    }))
  })
}

