import React from 'react';

const PreviewPdf = ({file}) => {
  return (
      <div className="relative w-full cursor-pointer">
        <iframe
          src={file}
          title="content"
          height="auto"
          width="100%"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-transparent"></div>
      </div>
  );
};

export default PreviewPdf;
