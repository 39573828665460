import React, { useEffect, useState } from "react";
import { getImageData } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/sidebar";
import {
  setJwtTokenCookie,
  setRefreshTokenCookie,
} from "../../../utils/cookieHelper";
import { getUserDetailsAction } from "../../../commonData/userDetails/action";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../utils/routes";
import {resetLoginMessage} from '../../../pages/Login/action';

export default function SignedInNavBar({ title = "Library", label = "home" }) {
  const dispatch = useDispatch();
  const uploadInfo = useSelector((state) => state.uploadImage);
  const profileImageData = getImageData(uploadInfo.payload, "profile");
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.getUserDetails.payload);

  const [dark, setDark] = React.useState(
    localStorage.getItem("color-theme") === "dark" ||
    (!("color-theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  );

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    (async() => dispatch(getUserDetailsAction()))();
  }, [dispatch]);

  // Update dark mode setting in localStorage and apply class to body
  const darkModeHandler = () => {
    const newDark = !dark;
    setDark(newDark);
    document.body.classList.toggle("dark", newDark);
    localStorage.setItem("color-theme", newDark ? "dark" : "light");
  };

  const signOut = async () => {
    await setJwtTokenCookie(null);
    await setRefreshTokenCookie(null);
    dispatch(resetLoginMessage());
    let path = ROUTES.home;
    navigate(path);
  };

  const settingsPage = () => {
    let path = ROUTES.accountSettings;
    navigate(path);
  };


  return (
    <div className="w-full z-50 text-center">
      <Sidebar navWidth={"55px"} label={label} />

      <nav className="flex fixed z-20 inset-x-0 ml-16 lg:ml-20 px-4 md:px-8 lg:px-12 justify-between h-16 lg:h-20 content-center bg-app-colors-header-bg dark:bg-app-colors-header-bg-d">
        <div className="my-auto sm:min-w-fit">
          <h6 className="uppercase text-white">{title}</h6>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6">
          <div className="ml-3 relative">
            <div className="flex justify-center">
              <div className="flex space-x-4">
                <div className="hidden mr-2 my-auto text-white">
                  <div>
                    <div
                      className="text-white bg-i-green-500 text-center text-xs rounded-full w-4 h-4 -mb-4 ml-4"
                      style={{ fontSize: "8px" }}
                    >
                      2
                    </div>
                    <button
                      id="connectionsDropdownButton"
                      data-dropdown-toggle="connectionsDropdown"
                      className="mr-6 text-white bg-transparent font-medium focus:ring-0 focus:outline-none text-sm px-0 py-2.5 text-center inline-flex items-center"
                      type="button"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 64 65"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_52244_11758)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M32 0.5C14.3264 0.5 0 14.82 0 32.4776C0 46.8104 9.4304 58.932 22.4256 63.0024V56.2216C13.0464 52.4232 6.4256 43.2424 6.4256 32.5032C6.4256 18.3624 17.888 6.9 32.0256 6.9C46.1632 6.9 57.6256 18.3624 57.6256 32.5032C57.6256 43.2424 51.0016 52.4232 41.6256 56.2216V62.9768C54.5952 58.8968 64 46.788 64 32.4776C64 14.82 49.6704 0.5 32 0.5ZM22.4224 26.1H16.0224V32.5032H22.4224V26.1ZM41.6256 26.1V32.5032H48.0256V26.1H41.6256ZM22.3968 39.2296C22.3968 42.58 26.688 45.2936 31.984 45.2936C37.28 45.2936 41.5712 42.58 41.5712 39.2296V38.9096H22.3968V39.2296ZM28.8256 64.5032H35.2256V58.1032H28.8256V64.5032Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_52244_11758">
                            <rect
                              width="64"
                              height="64"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <div
                      id="connectionsDropdown"
                      className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-64"
                    >
                      <div className="flex text-gray-800 text-xs p-3">
                        <div>Connection Requests</div>
                        <div className="ml-auto">Clear</div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className="text-white bg-i-orange-500 text-center text-xs rounded-full w-4 h-4 -mb-4 ml-4"
                      style={{ fontSize: "8px" }}
                    >
                      4
                    </div>
                    <button
                      id="notificationsDropdownButton"
                      data-dropdown-toggle="notificationsDropdown"
                      className="text-white bg-transparent font-medium focus:ring-0 focus:outline-none text-sm px-0 py-2.5 text-center inline-flex items-center"
                      type="button"
                    >
                      <svg
                        width="20"
                        height="24"
                        viewBox="0 0 52 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_52244_11685)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M51.2 22.3968H35.2L48 0L10.6624 0.0096L0 35.2032H9.5968L6.4 64L16 55.0848V45.2576L12.8 48L16 28.7968H9.6L16 6.3968H35.2L25.6 28.7968H35.2V37.2576L51.2 22.3968ZM22.4 44.8H28.8V38.4H22.4V44.8Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_52244_11685">
                            <rect width="51.2" height="64" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <div
                      id="notificationsDropdown"
                      className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-64"
                    >
                      <div className="flex text-gray-800 text-xs p-3">
                        <div>Library Requests</div>
                        <div className="ml-auto">Clear</div>
                      </div>
                      <ul
                        className="py-2 text-sm text-gray-700"
                        aria-labelledby="notificationsDropdownButton"
                      >
                        <li className="p-2 border-b border-gray-200">
                          <div className="flex">
                            <div className="mr-2">
                              <img
                                src="/images/test-avatar.png"
                                width="35px"
                                alt="avatar"
                                className="mr-2 rounded-full"
                              />
                            </div>
                            <div>
                              You and{" "}
                              <span className="font-bold">
                                Sandile Siyabonga
                              </span>{" "}
                              are now connected. View{" "}
                              <span className="text-i-orange-500">
                                his library.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="p-2 border-b border-gray-200">
                          <div className="flex">
                            <div className="mr-2">
                              <img
                                src="/images/test-avatar.png"
                                width="35px"
                                alt="avatar"
                                className="mr-2 rounded-full"
                              />
                            </div>
                            <div>
                              <span className="font-bold">
                                Sandile Siyabonga
                              </span>{" "}
                              granted you access to{" "}
                              <span className="text-i-orange-500">
                                his library.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="p-2 border-b border-gray-200">
                          <div className="flex">
                            <div className="mr-2">
                              <img
                                src="/images/test-avatar.png"
                                width="35px"
                                alt="avatar"
                                className="mr-2 rounded-full"
                              />
                            </div>
                            <div>
                              You and{" "}
                              <span className="font-bold">
                                Sandile Siyabonga
                              </span>{" "}
                              updated{" "}
                              <span className="text-i-orange-500">
                                his library.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="p-2 border-b border-gray-200">
                          <div className="flex">
                            <div className="mr-2">
                              <img
                                src="/images/test-avatar.png"
                                width="35px"
                                alt="avatar"
                                className="mr-2 rounded-full"
                              />
                            </div>
                            <div>
                              You and{" "}
                              <span className="font-bold">
                                Sandile Siyabonga
                              </span>{" "}
                              are now connected. View{" "}
                              <span className="text-i-orange-500">
                                his library.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="bg-i-orange-500 cursor-pointer text-center p-4 -mb-2 rounded-b">
                          <div className="text-white">
                            View all notifications
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  id="header-settings"
                  className="group/header-settings relative"
                  onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                >
                  <div
                    className="md:min-w-fit inline-flex gap-4 items-center justify-between text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400"
                    type="button"
                  >
                    {" "}
                    <div className="flex items-center gap-2">
                      <div className="relative w-10 min-w-10">
                        <img
                          src={
                            profileImageData
                              ? profileImageData["assetUrl"]
                              : "/images/test-avatar.jpg"
                          }
                          width="45px"
                          alt="profile"
                          className="surface object-cover w-10 h-10 rounded-full"
                        />
                        <span className="top-0 right-7 absolute w-3 h-3 bg-green-400 border-2 border-white dark:border-white rounded-full"></span>
                      </div>

                      <div className="hidden md:block">
                        <p className="font-medium text-app-colors-heading-d dark:text-app-colors-heading-d">
                          {`${userDetails && userDetails?.firstName} ${userDetails && userDetails?.lastName}`}
                        </p>
                        <p className="smaller text-left">{`@${userDetails && userDetails?.username}`}</p>
                      </div>
                    </div>
                    <svg
                      className="icons w-1.5"
                      style={{ transform: "rotate(-90deg)" }}
                      viewBox="0 0 36 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 28.444V35.5551H7.11111V28.444H0ZM13.6107 37.0271L8.58311 42.0547L28.6969 62.1649L33.7244 57.1374L13.6107 37.0271ZM28.6969 1.82715L8.58311 21.9409L13.6107 26.9685L33.7244 6.85826L28.6969 1.82715Z"
                        fill=""
                      ></path>
                    </svg>
                  </div>
                </div>

                {showDropdown && (
                  <div
                    className="group-hover/header-settings:block surface absolute right-0 mt-11 lg:mt-11 z-20 w-64 divide-y divide-gray-100 rounded-lg shadow dark:divide-gray-700"
                    aria-labelledby="settingsDropdown"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <div className="body block px-4 py-4 text-center rounded-t-lg">
                      <h6>Your Account</h6>
                    </div>
                    <div className="divide-y divide-gray-100 dark:divide-gray-700">
                      <div className="px-8 py-2">
                        <button className="group flex gap-6 items-center w-full py-2"
                          onClick={settingsPage}
                        >
                          <div className="flex items-center h-6 w-6">
                            <svg
                              className="icons"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_52244_11695)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.2222 7.76178V0H7.11111V7.76533C2.976 9.23378 0 13.1378 0 17.7778C0 22.4178 2.976 26.3218 7.11111 27.7902V49.7778H14.2222V27.7867C18.3573 26.3182 21.3333 22.4142 21.3333 17.7778C21.3333 13.1413 18.3573 9.23378 14.2222 7.76178ZM14.2222 21.3333H7.11111V14.2222H14.2222V21.3333ZM56.8889 7.76178V0H49.7778V7.76533C45.6462 9.23378 42.6667 13.1378 42.6667 17.7778C42.6667 22.4178 45.6462 26.3182 49.7778 27.7902V64H56.8889V27.7867C61.0204 26.3182 64 22.4142 64 17.7778C64 13.1413 61.0204 9.23378 56.8889 7.76178ZM56.8889 21.3333H49.7778V14.2222H56.8889V21.3333ZM35.5556 36.2098V0H28.4444V36.2098C24.3093 37.6782 21.3333 41.5822 21.3333 46.2222C21.3333 50.8587 24.3093 54.7627 28.4444 56.2347V64H35.5556V56.2347C39.6871 54.7627 42.6667 50.8551 42.6667 46.2222C42.6667 41.5822 39.6871 37.6782 35.5556 36.2098ZM35.5556 49.7742H28.4444V42.6667H35.5556V49.7742ZM7.11111 64H14.2222V56.8889H7.11111V64Z"
                                  fill=""
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_52244_11695">
                                  <rect width="64" height="64" fill="" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <p className="small font-medium text-hover-active">
                            Account Settings
                          </p>
                        </button>

                        <button
                          id="theme-toggle"
                          type="button"
                          className="group flex gap-6 items-center w-full py-2"
                          onClick={() => darkModeHandler()}
                        >
                          {dark ? (
                            <span>
                              <svg
                                className="icons"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#e8eaed"
                              >
                                <path d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z" />
                              </svg>
                            </span>
                          ) : (
                            <span>
                              <svg
                                className="icons"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#e8eaed"
                              >
                                <path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z" />
                              </svg>
                            </span>
                          )}
                          <p className="small font-medium text-hover-active">
                            Switch Theme
                          </p>
                        </button>

                        <button className="group flex gap-6 items-center w-full py-2">
                          <div className="flex items-center h-6 w-6">
                            <svg
                              className="icons"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 43 32"
                            >
                              <title>SignOut</title>
                              <path d="M26.667 3.557c4.962 0 9.232 2.91 11.23 7.111h3.838c-2.197-6.212-8.105-10.668-15.068-10.668s-12.873 4.457-15.070 10.667h3.84c1.998-4.199 6.268-7.109 11.23-7.109zM26.667 28.446c-4.962 0-9.232-2.91-11.23-7.111h-3.84c2.199 6.21 8.107 10.665 15.070 10.665 6.962 0 12.871-4.455 15.070-10.665h-3.838c-2 4.201-6.27 7.111-11.232 7.111zM23.111 17.778v-3.556h-16.306l3.252-3.25-2.514-2.514-7.543 7.541 7.543 7.543 2.514-2.514-3.252-3.252h16.306zM39.111 14.224v3.556h3.556v-3.556h-3.556z"></path>
                            </svg>
                          </div>
                          <p
                            onClick={signOut}
                            className="small font-medium text-hover-active"
                          >
                            SignOut
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="px-8 py-2">
                      {dark ? (
                        <img
                          id="logo-light"
                          className="h-14 sm:h-16 md:h-18 lg:h-20 xl:h-22"
                          src="/images/logo-light.svg"
                          alt="ticet logo"
                        />
                      ) : (
                        <img
                          id="logo-dark"
                          className="h-14 sm:h-16 md:h-18 lg:h-20 xl:h-22"
                          src="/images/logo-dark.svg"
                          alt="ticet logo"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
