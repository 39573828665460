import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_WORKSHEETS_LOADING,
  GET_WORKSHEETS_FAILURE,
  GET_WORKSHEETS_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getWorksheetsLoading = () => ({
  type: GET_WORKSHEETS_LOADING
});

export const getWorksheetsSuccess = payload => ({
  type: GET_WORKSHEETS_SUCCESS,
  payload
});

export const getWorksheetsFailure = error => ({
  type: GET_WORKSHEETS_FAILURE,
  error
});

export const getWorksheetsAction = (libraryId, subjectId) => async dispatch => {
  dispatch(getWorksheetsLoading());

  axios.get(`${CLIENT_API_URL}/worksheets?libraryId=${libraryId}&subjectId=${subjectId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        dispatch(getWorksheetsSuccess(res.data));
      })
      .catch(error => {
        dispatch(getWorksheetsFailure({
          message: 'An error occurred while fetching worksheets'
        }))
      })
}