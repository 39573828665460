import React, {useEffect, useState} from 'react';
import SignedInNavBar from '../../../components/navbars/signedInNavbar';
import LibraryClusterHeader from '../../../components/common/libraryClusterHeader';
import ConsolidatedLibraryCard from "../../../components/common/consolidatedLibraryCard";
import MyLibraryCard from '../../../components/common/MyLibraryCard';
import SuggestedLibraryCard from "../../../components/common/suggestedLibraryCard";
import {useDispatch, useSelector} from 'react-redux';
import { getFullName, isObjEmpty, findUserRole } from "../../../utils/common";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import ROUTES from '../../../utils/routes';

import {getSuggestedLibrariesAction} from '../../../commonData/suggestedLibraries/action';
import {followLibraryAction} from './action';

export default function MyLibrarySpace() {
  const [toggleState, setToggleState] = useState(1);
  const [suggestedToggleState, setSuggestedToggleState] = useState(1);

  const userDetails = useSelector((state) => state.getUserDetails.payload);
  const suggestedLibraries = useSelector((state) => state.getSuggestedLibraries.payload.data);
  const libraries = useSelector((state) => state.getLibraries.payload.data);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const suggestedToggleTab = (index) => {
    setSuggestedToggleState(index);
  };

  const personalLibraryPath = (libraryId) => {
    let path = `/user_space/${libraryId}/my_library_space/personal_library`;
    navigate(path);
  };

  useEffect(() => {
    if(params.libraryId) {
      ( async () => dispatch(getSuggestedLibrariesAction(params.libraryId, 1, 10))) ();
    }
  }, [dispatch, params.libraryId]);

  const role = isObjEmpty(userDetails) ? null : findUserRole(userDetails.roles);

  const handleFollowLibrary = async (libraryId) => {
    await dispatch(followLibraryAction(libraryId));
  }

  return role && role === "ROLE_INSTITUTION" ? (
    <Navigate to={ROUTES.personalLibrary} />
  ) : (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar label={"libraries"} title={"Library"} />
      <div className="mt-0 mx-0 lg:ml-20 min-h-screen">
        <LibraryClusterHeader />

        <div className="flex justify-center">
          <div className="flex flex-col w-full max-w-8xl mx-4 md:mx-8 lg:mx-10">
            <div className="body rounded-md flex z-10 sticky top-16 lg:top-20 mt-4 py-4 md:py-0 pt-2 md:pt-4 lg:pt-4 border-b border-gray-200 dark:border-gray-700">
              <div className="w-full flex gap-6 flex-col justify-between md:flex-row md:items-center">
                <ul className="flex gap-4 w-full md:w-fit text-center group-hover:text-primary-main-700">
                  <li className="me-2 flex-1 md:flex-none flex justify-center">
                    <button
                      className={`flex flex-col focus:outline-none w-full border-secondary-red-500 group gap-2 items-center justify-center lg:gap-4 px-4 md:px-8 lg:px-12 py-4 ${
                        toggleState === 1
                          ? "tabs" +
                            " border-b-2 hover:border-secondary-red-500"
                          : "text-app-colors-heading dark:text-app-colors-heading-d "
                      }`}
                      onClick={() => toggleTab(1)}
                    >
                      <span className="my-auto sm:mr-1">
                        <div className="w-5 h-5">
                          <svg
                            className="icons"
                            viewBox="0 0 58 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M38.7927 51.2V32.0032H45.1959V51.2H38.7927ZM25.9927 51V32.0032H32.3959V51H25.9927ZM16.4023 9.6V15.9968H10.0023V9.6H16.4023ZM26.0023 9.6V15.9968H19.6023V9.6H26.0023ZM35.6023 9.6V15.9968H29.2023V9.6H35.6023ZM51.6023 25.5968H6.80234V19.1968H51.6023V25.5968ZM51.6023 31.9968H58.0023V38.3968H51.6023V31.9968Z"
                              fill="#"
                            />
                            <path
                              d="M6.80234 25.5968V19.1968V6.4H51.6023V19.1968V25.5968V25.6H58.0023V6.4C58.0023 2.864 55.1351 0 51.6023 0H6.80234C3.26954 0 0.402344 2.864 0.402344 6.4V25.5968H6.80234Z"
                              fill="#"
                            />
                            <path
                              d="M45.2023 63.9936H6.80234H0.402344V57.5968H6.80234H38.8023L51.6023 57.6032V44.7936H58.0023V51.1968V64.0032L45.2023 63.9936Z"
                              fill="#"
                            />
                            <path
                              d="M13.2024 32.0032V51.2H19.6056V32.0032H13.2024Z"
                              fill="#"
                            />
                            <path
                              d="M0.402414 32.0032V51H6.80562V32.0032H0.402414Z"
                              fill="#"
                            />
                          </svg>
                        </div>
                      </span>
                      <p className="small font-semibold text-hover-active">
                        Consolidated Library
                      </p>
                    </button>
                  </li>

                  <li className="me-2 flex-1 md:flex-none flex justify-center">
                    <button
                      className={`flex flex-col focus:outline-none w-full border-secondary-red-500 group gap-2 items-center justify-center lg:gap-4 px-4 md:px-8 lg:px-12 py-4 ${
                        toggleState === 2
                          ? "tabs" +
                            " border-b-2 border-secondary-red-500 hover:border-secondary-red-500"
                          : "text-app-colors-heading dark:text-app-colors-heading-d"
                      }`}
                      onClick={() => toggleTab(2)}
                    >
                      <span className="my-auto sm:mr-1">
                        <div className="h-5 w-5">
                          <svg
                            className="icons"
                            viewBox="0 0 58 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M38.3904 51.2V32.0032H44.7936V51.2H38.3904ZM25.5904 51.2V32.0032H31.9936V51.2H25.5904ZM12.8 51.1744V31.9744H19.2V51.1744H12.8ZM16 9.6V15.9968H9.6V9.6H16ZM25.6 9.6V15.9968H19.2V9.6H25.6ZM35.2 9.6V15.9968H28.8V9.6H35.2ZM51.2 25.5968H6.4V19.1968H51.2V25.5968ZM44.8 63.9936H6.4H0V54.3936V44.7904V6.4C0 2.864 2.8672 0 6.4 0H51.2C54.7328 0 57.6 2.864 57.6 6.4V25.6H51.2V6.4H6.4V44.7936V57.5968H38.4L51.2 57.6032V44.7936H57.6V51.1968V64.0032L44.8 63.9936ZM51.2 31.9968H57.6V38.3968H51.2V31.9968Z"
                              fill="#"
                            />
                          </svg>
                        </div>
                      </span>
                      <p className="small font-semibold text-hover-active">
                        Libraries{" "}
                        <span className="text-hover-active text-secondary-yellow-600 dark:text-secondary-yellow-600">
                          { libraries && libraries.length || 0 }
                        </span>
                      </p>
                    </button>
                  </li>
                </ul>

                {
                  // filter, sort, and search commented out below
                  /* <div className="flex h-14 justify-between sm:gap-2 md:gap-4">
                  <div className="flex gap-2">
                    <button className="primary-button hidden [flex] justify-center items-center w-14 h-14 rounded-full text-white bg-primary-main-700">
                      <svg
                        width="22"
                        height="20"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.36194 16.0001L0.0281563 13.0001L2.47305 13.0001V10.0001H4.25083V13.0372L6.52818 13.0001L3.36194 16.0001Z"
                          fill="white"
                        />
                        <path
                          d="M2.47305 8.76331H4.25083V7.26331H2.47305V8.76331Z"
                          fill="white"
                        />
                        <path
                          d="M12.0293 9.40085H13.8066V3.00012H16.084L12.9178 0.00012207L9.58398 3.00012H12.0289L12.0293 9.40085Z"
                          fill="white"
                        />
                        <path
                          d="M6.02637 6.50012H10.5237L10.5257 5.00012H6.02637V6.50012Z"
                          fill="white"
                        />
                        <path
                          d="M6.02621 8.87844H10.5262L10.5243 7.37844H6.02621V8.87844Z"
                          fill="white"
                        />
                        <path
                          d="M6.02899 11.4009H10.5283L10.5264 9.90085H6.02899V11.4009Z"
                          fill="white"
                        />
                      </svg>
                    </button>

                    <button className="blue-button rounded-button flex justify-center items-center text-white">
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.7507 11.5556C10.7507 12.7156 11.4947 13.6916 12.5284 14.0587V16H14.3062V14.0587C15.3391 13.6916 16.084 12.7156 16.084 11.5556C16.084 10.3956 15.3391 9.41956 14.3062 9.05244V3.55556H12.5284V9.05244C11.4947 9.41956 10.7507 10.3956 10.7507 11.5556ZM14.3062 10.6667V12.4444H12.5284V10.6667H14.3062ZM5.41732 4.44444C5.41732 5.60444 6.16221 6.58044 7.1951 6.94756L7.1951 16H8.97287V6.94756C10.0067 6.58044 10.7507 5.60356 10.7507 4.44444C10.7507 3.28533 10.0058 2.30844 8.97287 1.94133L8.97287 0H7.1951V1.94133C6.16132 2.30844 5.41732 3.28444 5.41732 4.44444ZM8.97287 3.55556V5.33333H7.1951V3.55556H8.97287ZM1.86176 9.05244C0.827984 9.41956 0.0839844 10.3956 0.0839844 11.5556C0.0839844 12.7156 0.827984 13.6916 1.86176 14.0587L1.86176 16H3.63954V14.0587C4.67243 13.6916 5.41732 12.7156 5.41732 11.5556C5.41732 10.3956 4.67332 9.41956 3.63954 9.05244L3.63954 0H1.86176L1.86176 9.05244ZM3.63954 10.6667L3.63954 12.4444H1.86176V10.6667H3.63954ZM14.3062 1.77778L14.3062 0H12.5284L12.5284 1.77778H14.3062Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>

                  <form className="flex items-center surface pl-4 border rounded border-app-colors-border dark:border-app-colors-border-d focus:ring-app-colors-border dark:focus:ring-app-colors-border-d">
                    <div className="flex">
                      <div className="flex">
                        <div className="flex items-center pointer-events-none">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.5">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.4886 1.78514C8.10837 -0.595048 4.24932 -0.595048 1.86913 1.78514C-0.511063 4.16533 -0.511063 8.02514 1.86913 10.4046C4.24932 12.7848 8.10913 12.7848 10.4886 10.4046C12.8687 8.02514 12.8687 4.16533 10.4886 1.78514ZM9.41122 9.32724C7.62608 11.1124 4.73084 11.1124 2.94646 9.32724C1.16132 7.54209 1.16132 4.64762 2.94646 2.86248C4.7316 1.07733 7.62684 1.07733 9.41122 2.86248C11.1964 4.64686 11.1964 7.54209 9.41122 9.32724Z"
                                fill="#A3A7C4"
                              />
                              <path
                                d="M12.0957 10.9541L13.1729 9.87616L14.2514 10.9539L13.1742 12.0318L12.0957 10.9541Z"
                                fill="#A3A7C4"
                              />
                              <path
                                d="M14.2538 14.1825C13.9559 14.4812 13.4744 14.4812 13.1765 14.1825L9.94449 10.9505L9.52773 10.5246L9.83021 10.2222L11.6778 8.37454L11.0218 7.71777L9.94449 8.79511L8.8664 9.8732L7.78906 10.9505L8.8664 12.0279L12.0992 15.2599C12.9906 16.1513 14.4405 16.1513 15.3312 15.2599C16.2226 14.3692 16.2226 12.9193 15.3312 12.0279L14.2538 13.1052C14.5517 13.4031 14.551 13.8862 14.2538 14.1825Z"
                                fill="#A3A7C4"
                              />
                            </g>
                          </svg>
                        </div>
                        <input
                          type="search"
                          id="default-search"
                          className="surface py-4 text-sm border-0 focus:ring-0 text-gray-900 dark:text-white dark:placeholder-gray-400"
                          placeholder="Search Library ..."
                          required
                        />
                      </div>

                      <button
                        type="submit"
                        className="primary-button px-4 text-white focus:ring-0 focus:outline-none font-medium rounded text-sm"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div> */
                }
              </div>
            </div>

            <div className="mt-4 mb-6">
              <div className={`${toggleState === 1 ? "block" : "hidden"}`}>
                <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
                  <ConsolidatedLibraryCard cardName={"Mathematics"} />
                  <ConsolidatedLibraryCard cardName={"General Science"} />
                  <ConsolidatedLibraryCard cardName={"Biology"} />
                  <ConsolidatedLibraryCard cardName={"Geography"} />
                  <ConsolidatedLibraryCard cardName={"Kiswahili"} />
                  <ConsolidatedLibraryCard cardName={"Religious Studies"} />
                  <ConsolidatedLibraryCard cardName={"Physics"} />
                </div>
              </div>

              <div className={`${toggleState === 2 ? "block" : "hidden"}`}>
                <div className="flex gap-6">
                  <div className="flex-grow">
                    <div className="grid auto-rows-max grid-cols-auto-fill-260 gap-6">
                      {(libraries && libraries.length > 0 ) ? (
                        libraries.map((library, index) => (
                          <MyLibraryCard
                            key={index}
                            fullName={getFullName(library.user.firstName, library.user.lastName)}
                            username={library.user.username}
                            noOfFollowers={library.numberOfFollowers || 0}
                            role={library.userType}
                            libraryType={library?.libraryType?.toLowerCase()}
                            handleClick={() => personalLibraryPath(library.id)}
                          />
                        ))
                      ) : (
                          <div className="small">There are no libraries</div>
                      )}
                    </div>
                  </div>
                  <div className="hidden xl:flex xl:flex-col xl:flex-grow-0 xl:w-100">
                    <div className="lg:flex-col">
                      <div className="surface lg:pt-14 lg:pb-8 px-4 rounded-t border-b border-app-colors-border dark:border-app-colors-border-d">
                        <h6 className="text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                          Suggested Libraries
                        </h6>
                      </div>
                      <ul className="flex w-full text-center group-hover:text-primary-main-700 mt-2">
                        <li className="me-2 flex-1 justify-start">
                          <button
                            className={`focus:outline-none w-full border-secondary-red-500 group inline-flex gap-2 items-center justify-between sm:gap-4 px-2 py-4 sm:p-4 ${
                              suggestedToggleState === 1
                                ? "tabs" +
                                  " border-b-2 hover:border-secondary-red-500"
                                : "text-app-colors-heading dark:text-app-colors-heading-d "
                            }`}
                            onClick={() => suggestedToggleTab(1)}
                          >
                            <h6 className="text-hover-active text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                              My School
                            </h6>
                            <span className="my-auto sm:mr-1">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M3.64739 13.1386C3.12194 13.1386 2.71299 12.7883 2.5378 12.3211C2.3044 11.5618 2.1875 10.8029 2.1875 9.9855C2.1875 5.83932 5.57435 2.33599 9.72012 2.2191H9.89527V0.642354C9.89527 0.408955 10.0122 0.17515 10.2456 0.0582476C10.3625 6.27813e-07 10.479 0 10.5959 0C10.7128 0 10.8293 0.0582485 10.9462 0.116904L15.1506 2.80324C15.3257 2.92014 15.4426 3.15354 15.4426 3.38735C15.4426 3.62074 15.3257 3.85455 15.1506 3.97145L10.9462 6.65779C10.7128 6.77469 10.479 6.83294 10.2456 6.65779C10.0122 6.54088 9.89527 6.30748 9.89527 6.07368V4.49694H9.72012C6.80042 4.61384 4.52305 7.00767 4.52305 9.92737C4.52305 10.4528 4.5813 11.037 4.75645 11.5624C4.87336 11.8545 4.8147 12.1465 4.69821 12.4381C4.5813 12.7302 4.28925 12.9054 3.9976 13.0223C3.88111 13.1388 3.7643 13.1386 3.64739 13.1386ZM10.1291 17.7515H9.95398V19.3282C9.95398 19.5616 9.83708 19.7954 9.60368 19.9123C9.37028 20.0292 9.07823 20.0292 8.90308 19.9123L4.69871 17.226C4.52356 17.1091 4.40665 16.8757 4.40665 16.6419C4.40665 16.4085 4.52356 16.1747 4.69871 16.0578L8.90308 13.3714C9.01998 13.3132 9.13647 13.2545 9.25338 13.2545C9.37028 13.2545 9.48678 13.2545 9.60368 13.3128C9.83708 13.4297 9.95398 13.6631 9.95398 13.8969V15.415H10.1291C13.0488 15.2981 15.3844 12.9043 15.3844 9.9846C15.3844 9.45914 15.3261 8.87501 15.151 8.34956C15.0341 8.05751 15.0927 7.76546 15.2092 7.47382C15.3261 7.18177 15.6182 7.00662 15.9098 6.88971C16.2019 6.77281 16.4939 6.83147 16.7856 6.94796C17.0776 7.06486 17.2528 7.35692 17.3697 7.64856C17.6031 8.40781 17.72 9.1667 17.72 9.98411C17.7204 14.1893 14.3335 17.635 10.1291 17.7515Z" />
                              </svg>
                            </span>
                          </button>
                        </li>
                        <li className="me-2 flex-1 justify-start">
                          <button
                            className={`focus:outline-none w-full border-secondary-red-500 group inline-flex gap-2 items-center justify-between sm:gap-4 px-2 py-4 sm:p-4 ${
                              suggestedToggleState === 2
                                ? "tabs" +
                                  " border-b-2 hover:border-secondary-red-500"
                                : "text-app-colors-heading dark:text-app-colors-heading-d "
                            }`}
                            onClick={() => suggestedToggleTab(2)}
                          >
                            <h6 className="text-hover-active text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                              Popular
                            </h6>
                            <span className="my-auto sm:mr-1">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M3.64739 13.1386C3.12194 13.1386 2.71299 12.7883 2.5378 12.3211C2.3044 11.5618 2.1875 10.8029 2.1875 9.9855C2.1875 5.83932 5.57435 2.33599 9.72012 2.2191H9.89527V0.642354C9.89527 0.408955 10.0122 0.17515 10.2456 0.0582476C10.3625 6.27813e-07 10.479 0 10.5959 0C10.7128 0 10.8293 0.0582485 10.9462 0.116904L15.1506 2.80324C15.3257 2.92014 15.4426 3.15354 15.4426 3.38735C15.4426 3.62074 15.3257 3.85455 15.1506 3.97145L10.9462 6.65779C10.7128 6.77469 10.479 6.83294 10.2456 6.65779C10.0122 6.54088 9.89527 6.30748 9.89527 6.07368V4.49694H9.72012C6.80042 4.61384 4.52305 7.00767 4.52305 9.92737C4.52305 10.4528 4.5813 11.037 4.75645 11.5624C4.87336 11.8545 4.8147 12.1465 4.69821 12.4381C4.5813 12.7302 4.28925 12.9054 3.9976 13.0223C3.88111 13.1388 3.7643 13.1386 3.64739 13.1386ZM10.1291 17.7515H9.95398V19.3282C9.95398 19.5616 9.83708 19.7954 9.60368 19.9123C9.37028 20.0292 9.07823 20.0292 8.90308 19.9123L4.69871 17.226C4.52356 17.1091 4.40665 16.8757 4.40665 16.6419C4.40665 16.4085 4.52356 16.1747 4.69871 16.0578L8.90308 13.3714C9.01998 13.3132 9.13647 13.2545 9.25338 13.2545C9.37028 13.2545 9.48678 13.2545 9.60368 13.3128C9.83708 13.4297 9.95398 13.6631 9.95398 13.8969V15.415H10.1291C13.0488 15.2981 15.3844 12.9043 15.3844 9.9846C15.3844 9.45914 15.3261 8.87501 15.151 8.34956C15.0341 8.05751 15.0927 7.76546 15.2092 7.47382C15.3261 7.18177 15.6182 7.00662 15.9098 6.88971C16.2019 6.77281 16.4939 6.83147 16.7856 6.94796C17.0776 7.06486 17.2528 7.35692 17.3697 7.64856C17.6031 8.40781 17.72 9.1667 17.72 9.98411C17.7204 14.1893 14.3335 17.635 10.1291 17.7515Z" />
                              </svg>
                            </span>
                          </button>
                        </li>
                      </ul>

                      <div className="mt-4">
                        <div
                            className={`flex flex-col gap-4 ${suggestedToggleState === 1 ? 'block' : 'hidden'}`}
                        >
                          {
                            (suggestedLibraries && suggestedLibraries?.content?.length > 0) ? (
                                suggestedLibraries?.content?.map((library) => (
                                  <div>
                                    <SuggestedLibraryCard
                                      fullName={getFullName(library?.user?.firstName, library?.user?.lastName)}
                                      username={library?.user?.username}
                                      noOfFollowers={'0'}
                                      curriculumLevel={library?.curriculumLevel?.level}
                                      noOfUploads={'1,020'}
                                      handleFollowLibrary={() => handleFollowLibrary(library?.id)}
                                    />
                                  </div>
                                ))
                            ) : (
                                <div className="small">There are no suggested libraries</div>
                            )
                          }
                          <div>
                            <SuggestedLibraryCard
                              fullName={'Kisembo Abidemi Enyonam'}
                              username={'efeuzoma'}
                              noOfFollowers={'0'}
                              curriculumLevel={'cbc lower primary'}
                              noOfUploads={'1,020'}
                              // handleFollowLibrary={() => handleFollowLibrary(library?.id)}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                            className={`flex flex-col gap-4 ${suggestedToggleState === 2 ? 'block' : 'hidden'}`}
                        >
                          {
                            (suggestedLibraries && suggestedLibraries?.content?.length > 0) ? (
                                suggestedLibraries?.content?.map((library) => (
                                  <div>
                                    <SuggestedLibraryCard
                                      fullName={getFullName(library?.user?.firstName, library?.user?.lastName)}
                                      username={library?.user?.username}
                                      noOfFollowers={'0'}
                                      curriculumLevel={library?.curriculumLevel?.level}
                                      noOfUploads={'1,020'}
                                      handleFollowLibrary={() => handleFollowLibrary(library?.id)}
                                    />
                                  </div>
                                ))
                            ) : (
                                <div className="small">There are no suggested libraries</div>
                            )
                          }
                          <div>
                            <SuggestedLibraryCard
                              fullName={'Kisembo Abidemi Enyonam'}
                              username={'efeuzoma'}
                              noOfFollowers={'0'}
                              curriculumLevel={'cbc lower primary'}
                              noOfUploads={'1,020'}
                              // handleFollowLibrary={() => handleFollowLibrary(library?.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
