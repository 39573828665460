import React from "react";

const WorkSpaceItem = ({
  item = "",
  img = "",
  itemDescription = "",
  noOfItems = null,
  handleClick = () => {},
}) => {
  return (
    <div className="group relative surface surface-hover rounded-md cursor-pointer aspect-ratio-4-3 sm:aspect-ratio-1-1"
      onClick={handleClick}>
      <div className="h-full overflow-hidden bg-no-repeat bg-cover bg-center rounded-md ">
        <img src={img} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="min-h-2-lines absolute flex flex-col -bottom-0.5 w-full text-center px-2 py-4 surface border-t border-app-colors-border dark:border-app-colors-border-d rounded-b-md items-center flex-nowrap justify-center transition-all duration-1000">
        <h6 className="item-title text-hover-active text-base line-clamp-2">
          {item}
        </h6>
        <span className="text-base text-secondary-yellow-500">
          {noOfItems && `${noOfItems}`}
        </span>
        <p className="item-description small hidden group-hover:block line-clamp-2">
          {itemDescription}
        </p>
      </div>
    </div>
  );
};

export default WorkSpaceItem;
