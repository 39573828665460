import React, { useEffect } from "react";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import { findUserRole, getImageData, isObjEmpty } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import LibraryClusterHeader from "../../../components/common/libraryClusterHeader";
import LibraryClusterCard from "../../../components/common/libraryClusterCard";
import { getLibrariesAction } from "./action";

export default function UserSpaceLibrary() {
  const uploadInfo = useSelector((state) => state.uploadImage);
  const userDetails = useSelector((state) => state.getUserDetails.payload);
  const profileImageData = getImageData(uploadInfo.payload, "profile");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const libraries = useSelector((state) => state.getLibraries.payload.data);

  useEffect(() => {
    dispatch(getLibrariesAction());
  }, [dispatch]);

  const consolidatedLibraryRoutePage = (libraryId) => {
    const role = isObjEmpty(userDetails) ? null : findUserRole(userDetails.roles);
    let path =
      role === "ROLE_INSTITUTION"
        ? `/user_space/${libraryId}/my_library_space/personal_library`
        : `/user_space/${libraryId}/my_library_space`;
    navigate(path);
  };

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar label={"libraries"} title={"Library"} />
      <div className="mt-0 mx-0 lg:ml-20  min-h-screen">
        <LibraryClusterHeader />
        <div className="flex justify-center">
          <div className="max-w-8xl mx-4 md:mx-8 lg:mx-20 pt-2 md:pt-4 lg:pt-6 grid gap-5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {libraries && libraries.length > 0 ? (
              libraries.map((library) => (
                <LibraryClusterCard
                  fullName={library.curriculumLevel.level}
                  librariesFollowed={
                    library.numberOfFollowers ? library.numberOfFollowers : 0
                  }
                  status={library.status}
                  key={library.id}
                  handleConsolidatedLibraryRoutePage={() =>
                    consolidatedLibraryRoutePage(library.id)
                  }
                />
              ))
            ) : (
              <div className="flex items-center gap-4 surface surface-hover min-w-72 rounded p-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 64 64"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_52244_11715)">
                    <path
                      d="M32 0C14.3264 0 0 14.3168 0 31.9776C0 46.3072 9.4272 58.4288 22.4256 62.4992V55.7216C13.0464 51.92 6.4256 42.7424 6.4256 32.0032C6.4256 17.8656 17.888 6.4064 32.0256 6.4064C46.1632 6.4064 57.6256 17.8656 57.6256 32.0032C57.6256 42.7424 51.0016 51.9264 41.6256 55.7216V62.4736C54.5952 58.3936 64 46.288 64 31.9776C64 14.3168 49.6672 0 32 0ZM28.8256 48.0032H35.2256V28.8032H28.8256V48.0032ZM28.8256 22.4032H35.2256V16.0032H28.8256V22.4032ZM28.8256 64H35.2256V57.6H28.8256V64Z"
                      fill="#f8583b"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_52244_11715">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>There are no libraries</p>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
