import {getJwtTokenCookie, getRefreshTokenCookie, setJwtTokenCookie} from '../cookieHelper';
import axios from '../api/axios';
import {CLIENT_API_URL} from '../constants';
import {parseJwt} from '../common';

export const authVerify = async () => {
  let token = await getJwtTokenCookie();

  if (!token) {
    //console.log('No token found.');
    return 'missing token';
  }

  const decodedToken = parseJwt(token);

  // If token doesn't have an expiration time or is expired
  if (!decodedToken?.exp || decodedToken.exp * 1000 <= Date.now()) {
    //console.log('Token is invalid or expired, attempting refresh...');

    // Attempt to refresh token
    try {
      const response = await axios.post(
          `${CLIENT_API_URL}/users/auth/token`,
          JSON.stringify({ refreshToken: await getRefreshTokenCookie() }),
          { headers: { 'Content-Type': 'application/json' } }
      );

      // Set the refreshed token and return it
      token = response.data.data.access_token;
      await setJwtTokenCookie(token);
      //console.log('Token refreshed successfully.');
      return token;

    } catch (error) {
      //console.log('Token refresh failed, logging out.');
      await setJwtTokenCookie(null); // Clear token if refresh fails
      return 'missing token';
    }
  }

  // Token is valid and not expired
  //console.log('Token is valid.');
  return token;
};
