import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_SUBJECTS_LOADING,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECTS_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getSubjectsLoading = () => ({
  type: GET_SUBJECTS_LOADING
});

export const getSubjectsSuccess = payload => ({
  type: GET_SUBJECTS_SUCCESS,
  payload
});

export const getSubjectsFailure = error => ({
  type: GET_SUBJECTS_FAILURE,
  error
});

export const getSubjectsAction = (curriculumLevelId) => async dispatch => {
  console.log("curriculumLevelId Id-->", curriculumLevelId)
  dispatch(getSubjectsLoading());

  axios.get(`${CLIENT_API_URL}/subjects?curriculumLevelId=${curriculumLevelId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        console.log('Subjects action called --');
        console.log('Subjects -->', res.data);
        dispatch(getSubjectsSuccess(res.data));
      })
      .catch(error => {
        console.log('Subjects action failed --');
        console.log('Subjects action failed --', error);
        dispatch(getSubjectsFailure({
          message: 'An error occurred while fetching subjects'
        }))
      })
}