import React from 'react';
import PreviewPdf from '../../utils/previewPDF';

const WorksheetItem = ({worksheetUrl='', title='', description='', courseMaterial='',  authorUserName='', handleClick}) => {
  return (
      <div
          className="group surface surface-hover relative cursor-pointer  border-1 borders w-full h-56 rounded surface overflow-hidden"
          onClick={handleClick}
      >
        <div className="surface h-auto overflow-hidden">
          <PreviewPdf
              file={worksheetUrl}
              className="w-full h-full object-cover"
          />
        </div>
        <div
            className="surface flex flex-col justify-between group-hover:bg-app-colors-surface-h dark:group-hover:bg-app-colors-surface-d-h absolute bottom-0 min-h-2-lines w-full p-2">
          <h6 className="custom-line-clamp text-sm text-hover-active">
            {title}
          </h6>
          <div className="fileDescription-tile group-hover:block">
            <p className="small line-clamp-3">
              {description}
            </p>
            <button className="flex gap-1 py-1">
              <img
                  src="/images/test-avatar.png"
                  width="20px"
                  alt="avatar"
                  className="mr-2 rounded-full"
              />
              <p className="smaller text-hover">
                {authorUserName}
              </p>
            </button>
          </div>

          <p className="smaller uppercase pt-2">
            {courseMaterial}
          </p>
        </div>
      </div>
  );
};

export default WorksheetItem;